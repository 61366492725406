<div class="container_image bg_1 width_2 animated zoomIn fast">
  <div *ngIf="viewHeader" class="card_imagen_header">
    <div class="title_header fw_500 fz_20 ml-4">Citas de usuario</div>
    <button type="button" id="idCerrarCitas" class="btn btn-circle btn_back" title="Cerrar"
      (click)="closeModalAndReset()"><i class="ti ti-close"></i> </button>
  </div>

  <div *ngIf="type==='Veterinary'" id="modal_user" class="image_body" [class.body_height_calc]="viewHeader">
    <div style="padding: 10px;">
      <div class="tab-content" id="pills-tabContent">
        <ng-container *ngIf="appointments.length>0 && !makeNewAppointment; else noAppointmentsPets">
          <app-appointments-user (rescheduling)="updateRescheduling($event)" [user]="user" [pets]="pets"
            [petCase]="petCase" [petSelected]="petSelected" [appointments]="appointments" [appointmentType]="type"
            [inputSubServiceId]="inputSubServiceId" [inputColumns]="inputColumns" [documentNumber]="documentNumber"
            [documentNumberAcudiente]="documentNumberAcudiente" [branchSelected]="branchSelected"
            [tempAppointment]="tempAppointment">
          </app-appointments-user>
          <div class="text-center padding-vertical">
            <custom-buttons *ngIf="isRescheduling===false" text="Sacar nueva cita"
              title="Permite agregar una nueva cita para este usuario" textColor="#fff" type="button" icon="fa fa-plus"
              width="250px" (click)="nextNewAppointment()" />
          </div>
        </ng-container>
        <ng-template #noAppointmentsPets>
          <ng-container [ngTemplateOutlet]="currentView"></ng-container>
          <ng-container *ngIf="viewCitas">
            <app-user-citas-view [idInput]="'idCitasView'" [pasosInput]="pasos" [appointmentType]="type"
              [disableIncome]="disableIncome" [documentNumber]="documentNumber"
              [documentNumberAcudiente]="documentNumberAcudiente" [inputSubServiceId]="inputSubServiceId"
              [branchSelected]="branchSelected" [userCase]="userCase" [guardianCase]="guardianCase" [petCase]="petCase"
              [pets]="pets" [user]="user" [petSelected]="petSelected" [relationshipUser]="relationshipUser"
              [tempAppointment]="tempAppointment" [relationshipAcudiente]="relationshipAcudiente"
              [appointments]="appointments">
            </app-user-citas-view>
          </ng-container>
        </ng-template>
      </div>
    </div>
  </div>

  <div *ngIf="type!=='Veterinary'" id="modal_user" class="image_body" [class.body_height_calc]="viewHeader">
    <div style="padding: 10px;">
      <div class="tab-content" id="pills-tabContent">
        <ng-container *ngIf="appointments.length>0 && !makeNewAppointment; else noAppointments">
          <app-appointments-user (rescheduling)="updateRescheduling($event)" [user]="user" [pets]="pets"
            [appointmentType]="type" [appointments]="appointments" [inputSubServiceId]="inputSubServiceId"
            [documentNumber]="documentNumber" [documentNumberAcudiente]="documentNumberAcudiente"
            [branchSelected]="branchSelected" [tempAppointment]="tempAppointment">
          </app-appointments-user>

          <div *ngIf="isRescheduling===false" class="text-center padding-vertical">
            <custom-buttons text="Sacar nueva cita" title="Permite agregar una nueva cita para este usuario"
              textColor="#fff" type="button" icon="fa fa-plus" width="250px" (click)="nextNewAppointment()" />
          </div>
        </ng-container>

        <ng-template #noAppointments>
          <ng-container [ngTemplateOutlet]="currentView"></ng-container>
          <ng-container *ngIf="viewCitas">
            <app-user-citas-view [pasosInput]="pasos" [appointmentType]="type" [disableIncome]="disableIncome"
              [documentNumber]="documentNumber" [documentNumberAcudiente]="documentNumberAcudiente"
              [inputSubServiceId]="inputSubServiceId" [branchSelected]="branchSelected" [userCase]="userCase"
              [guardianCase]="guardianCase" [relationshipUser]="relationshipUser" [user]="user"
              [tempAppointment]="tempAppointment" [relationshipAcudiente]="relationshipAcudiente"
              [appointments]="appointments">
            </app-user-citas-view>
          </ng-container>
        </ng-template>
      </div>
    </div>
  </div>

</div>

<ng-template #userExists>
  <app-form-section [number]="'1'" [sectionName]="'Usuario Encontrado'"></app-form-section>
  <div class="user_exist">
    <img *ngIf="user.avatar" [src]="user.avatar|getImage" style="height: 70px;" alt="">
    <div style="margin-left: 10px;"><span *ngIf="user.names">{{user.names}} </span><span
        *ngIf="user.lastNames">{{user.lastNames}}</span> <span
        *ngIf="this.edadActual !== null && this.edadActual !== undefined &&  edadActual < 18" class="menor_edad"
        title="Debe seleccionar a un Acudiente"> Menor de Edad </span></div>
  </div>
  <div class="settings_btn mt-4 mb-4">
    <custom-buttons *ngIf="!userExisteConfirmar" [rounded]="true" text="Confirmar Paciente" title="Confirmar"
      icon="ti-check ml-2" type="button" (click)="confirmarUserExiste()" />

    <custom-buttons *ngIf="userExisteConfirmar" [rounded]="true" [inputBackgroundColor]="'danger'"
      text="Cancelar confirmacion Paciente" title="Cancelar" icon="ti-close ml-2" type="button"
      (click)="cancelarUserExiste()" />
  </div>

  <ng-container *ngIf="viewAcudiente">
    <div class="animated fadeIn">
      <app-form-section [number]="'2'" [sectionName]="'Datos del Acudiente'"></app-form-section>
      <div id="idBuscarAcudiente" class="row">
        <div class="col-12">
          <app-search-input [placeholderInput]="'Buscar acudiente por número de documento'" [microphone]="false"
            [visibleShadow]="true" (searchValueEmmiter)="searchValueEmmiter($event)">
          </app-search-input>
          <div *ngIf="errorDocument.length>0" class="mt-2 txt_error">{{errorDocument}}</div>
        </div>

        <div class="col-12" style="padding: 10px;">
          <ng-container [ngTemplateOutlet]="currentAcudienteView"></ng-container>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="viewPets">
    <div class="animated fadeIn">
      <app-form-section [number]="'2'" [sectionName]="'Datos de la Mascota'"></app-form-section>
      <div id="idBuscarPets" class="row">

        <div class="col-12" style="padding: 10px;">
          <ng-container [ngTemplateOutlet]="currentPetsView"></ng-container>
        </div>

      </div>
    </div>
  </ng-container>

</ng-template>

<ng-template #userNotExists>
  <form id="idUserForm" [formGroup]="userForm!" class="mt-2 animated fadeIn">
    <app-form-section [number]="'1'" [sectionName]="'Registrar Paciente'"></app-form-section>
    <div class="row">
      <div class="col-12 txt_title_form">
        El Paciente que busco no existe, si desea registrarlo complete el siguiente formulario
      </div>
      <div class="col-xl-4 col-md-6 col-xs-12">
        <div class="form-group">
          <form-input [id]="'idInputNumeroDocumento'"
            helper="Para modificar el número de documento vuelve a Buscar un Paciente" label="Numero Documento"
            placeholder="número documento" [controlName]="'numeroDocumento'">
          </form-input>
        </div>
      </div>
      <div class="col-xl-4 col-md-6 col-xs-12">
        <div class="form-group">
          <form-date [id]="'idInputFechaNacimiento'" label="Fecha de Nacimiento" [maxInput]="fechaHoy"
            [controlName]="'fechaNacimiento'">
          </form-date>
        </div>
      </div>
      <div class="col-xl-4 col-md-6 col-xs-12">
        <div class="form-group">
          <form-input [id]="'idInputYears'" helper="Si el paciente es menor de 18 años, debes registrar a un acudiente"
            [required]="false" label="Edad Actual" placeholder="Edad en años" [controlName]="'years'">
          </form-input>
        </div>
      </div>
      <div class="col-xl-4 col-md-6 col-xs-12">
        <div class="form-group">
          <form-select [id]="'idInputTipoDocumento'" helper="Selecciona un tipo de documento de la lista"
            label="Tipo documento" placeholder="Seleccione..." [dataList]="filteredTypesOfDocument"
            [controlName]="'tipoDocumento'" [idField]="'type'" [valueField]="'displayValue'">
          </form-select>
        </div>
      </div>

      <div class="col-xl-4 col-md-6 col-xs-12">
        <div class="form-group">
          <form-input [id]="'idInputNombres'" helper="" label="Nombres" placeholder="Escribre los nombres del paciente"
            [controlName]="'nombres'">
          </form-input>
        </div>
      </div>
      <div class="col-xl-4 col-md-6 col-xs-12">
        <div class="form-group">
          <form-input [id]="'idInputApellidos'" helper="" label="Apellidos"
            placeholder="Escribre los apellidos del paciente" [controlName]="'apellidos'">
          </form-input>
        </div>
      </div>

      <div class="col-xl-4 col-md-6 col-xs-12">
        <div class="form-group">
          <form-autocomplete [id]="'idInputMunicipio'"
            helper="Escribe parte del nombre y da click sobre el municipio que deseas seleccionar" label="Municipio"
            [placeholder]="'Busca tu Municipio'" [dataList]="municipalities" [controlName]="'municipio'"
            [idField]="'_id'" [valueField]="'municipality'">
          </form-autocomplete>
        </div>
      </div>

      <div class="col-xl-4 col-md-6 col-xs-12">
        <div class="form-group">
          <form-input [id]="'idInputDireccion'" label="Dirección" [required]="false" placeholder="Dirección"
            [controlName]="'direccion'">
          </form-input>
        </div>
      </div>
      <div class="col-xl-4 col-md-6 col-xs-12">
        <div class="form-group">
          <form-input [id]="'idInputBarrio'" label="Barrio" [required]="false" placeholder="Barrio"
            [controlName]="'barrio'">
          </form-input>
        </div>
      </div>

      <div class="col-xl-4 col-md-6 col-xs-12">
        <div class="form-group">
          <form-input [id]="'idInputTelefono'" helper="" label="Número celular del paciente"
            placeholder="Registre el telefono del paciente" [controlName]="'telefono'" [customErrors]="{
              required: 'El número de teléfono es obligatorio',
              pattern: 'El número debe empezar con 3 y tener exactamente 10 dígitos'
            }">
          </form-input>
        </div>
      </div>
      <div class="col-xl-4 col-md-6 col-xs-12">
        <div class="form-group">

          <form-email [id]="'idInputCorreo'" helper="" label="Correo electrónico" placeholder="ejemplo@mail.com"
            [controlName]="'correo'" [required]="isEmailRequired">
          </form-email>


        </div>
      </div>

      <div class="col-xl-4 col-md-6 col-xs-12">
        <div class="form-group">
          <form-select [id]="'idInputGenero'" [required]="false" helper="" label="Género" placeholder="Seleccione..."
            [dataList]="genders" [controlName]="'genero'" [idField]="'value'" [valueField]="'gender'">
          </form-select>
        </div>
      </div>
    </div>
    <div class="settings_btn mt-4 mb-4">

      <custom-buttons *ngIf="!userConfirmar" [rounded]="true" text="Confirmar datos del Paciente" title="Confirmar"
        icon="ti-check ml-2" type="button" (click)="confirmarUser(userForm!)" />

      <custom-buttons *ngIf="userConfirmar" [rounded]="true" [inputBackgroundColor]="'danger'"
        text="Cancelar confirmacion Paciente" title="Cancelar" icon="ti-close ml-2" type="button"
        (click)="cancelarUser()" />

    </div>
  </form>

  <ng-container *ngIf="userConfirmar && viewAcudiente">
    <div class="animated fadeIn">
      <app-form-section [number]="'2'" [sectionName]="'Datos del Acudiente'"></app-form-section>
      <div id="idBuscarAcudiente" class="row">
        <div class="col-12">
          <app-search-input [placeholderInput]="'Buscar acudiente por número de documento'" [microphone]="false"
            [visibleShadow]="true" (searchValueEmmiter)="searchValueEmmiter($event)">
          </app-search-input>
          <div *ngIf="errorDocument.length>0" class="mt-2 txt_error">{{errorDocument}}</div>
        </div>

        <div class="col-12" style="padding: 10px;">
          <ng-container [ngTemplateOutlet]="currentAcudienteView"></ng-container>
        </div>
      </div>
    </div>
  </ng-container>


  <ng-container *ngIf="userConfirmar && viewPets">
    <div class="animated fadeIn">
      <app-form-section [number]="'2'" [sectionName]="'Datos de la Mascota'"></app-form-section>
      <div id="idBuscarPets" class="row">
        <div class="col-12" style="padding: 10px;">
          <ng-container [ngTemplateOutlet]="currentPetsView"></ng-container>
        </div>
      </div>
    </div>
  </ng-container>
</ng-template>

<ng-template #acudienteFormView>
  <ng-container *ngIf="acudienteForm && viewAcudiente">
    <form id="idFormAcudiente" [formGroup]="acudienteForm" class="mt-2 animated fadeIn">
      <div class="row">
        <div class="col-12 txt_title_form">
          El Acudiente que busco no existe, si desea registrarlo complete el siguiente formulario
        </div>
        <div class="col-xl-4 col-md-6 col-xs-12">
          <div class="form-group">
            <form-input [id]="'idInputNumeroDocumentoAc'"
              helper="Para modificar el número de documento vuelve a Buscar un Acudiente" label="Numero Documento"
              placeholder="número documento" [controlName]="'numeroDocumentoAc'">
            </form-input>
          </div>
        </div>

        <div class="col-xl-4 col-md-6 col-xs-12">
          <div class="form-group">
            <form-date [id]="'idInputFechaNacimientoAc'" label="Fecha de Nacimiento" [maxInput]="fechaHoy"
              [controlName]="'fechaNacimientoAc'" [customErrors]="customErrors">
            </form-date>
          </div>
        </div>
        <div class="col-xl-4 col-md-6 col-xs-12">
          <div class="form-group">
            <form-input [id]="'idInputYearsAc'" helper="El acudiente debe ser mayor de 18 años" [required]="false"
              label="Edad Actual" placeholder="Edad en años" [controlName]="'yearsAc'">
            </form-input>
          </div>
        </div>
        <div class="col-xl-4 col-md-6 col-xs-12">
          <div class="form-group">
            <form-select [id]="'idInputTipoDocumentoAc'" helper="Selecciona un tipo de documento de la lista"
              label="Tipo documento" placeholder="Seleccione..." [dataList]="typesOfDocument"
              [controlName]="'tipoDocumentoAc'" [idField]="'type'" [valueField]="'type'">
            </form-select>
          </div>
        </div>

        <div class="col-xl-4 col-md-6 col-xs-12">
          <div class="form-group">
            <form-input [id]="'idInputNombresAc'" helper="" label="Nombres"
              placeholder="Escribre los nombres del acudiente" [controlName]="'nombresAc'">
            </form-input>
          </div>
        </div>
        <div class="col-xl-4 col-md-6 col-xs-12">
          <div class="form-group">
            <form-input [id]="'idInputApellidosAc'" helper="" label="Apellidos"
              placeholder="Escribre los apellidos del acudiente" [controlName]="'apellidosAc'">
            </form-input>
          </div>
        </div>

        <div class="col-xl-4 col-md-6 col-xs-12">
          <div class="form-group">
            <form-select [id]="'idInputRelationship'"
              helper="Seleccione de la lista un parentesco del acudiente con el paciente"
              label="Parentesco con el paciente" placeholder="Seleccione..." [dataList]="relationShips"
              [controlName]="'relationship'" [idField]="'value'" [valueField]="'name'">
            </form-select>
          </div>
        </div>

        <div class="col-xl-4 col-md-6 col-xs-12">
          <div class="form-group">
            <form-input [id]="'idInputTelefonoAc'" helper="" label="Número de celular del acudiente"
              placeholder="Registre el telefono del acudiente" [controlName]="'telefonoAc'">
            </form-input>
          </div>
        </div>
        <div class="col-xl-4 col-md-6 col-xs-12">
          <div class="form-group">
            <form-email [id]="'idInputCorreoAc'" helper="" label="Correo electrónico" placeholder="ejemplo@mail.com"
              [customErrors]="customErrors" [controlName]="'correoAc'">
            </form-email>
          </div>
        </div>

        <div class="col-xl-4 col-md-6 col-xs-12">
          <div class="form-group">
            <form-autocomplete [id]="'idInputMunicipioAc'"
              helper="Escribe parte del nombre y da click sobre el municipio que deseas seleccionar" label="Municipio"
              [placeholder]="'Busca tu Municipio'" [dataList]="municipalities" [controlName]="'municipioAc'"
              [idField]="'_id'" [valueField]="'municipality'">
            </form-autocomplete>
          </div>
        </div>

        <div class="col-xl-4 col-md-6 col-xs-12">
          <div class="form-group">
            <form-input [id]="'idInputDireccionAc'" label="Dirección" [required]="false" placeholder="Dirección"
              [controlName]="'direccionAc'">
            </form-input>
          </div>
        </div>

        <div class="col-xl-4 col-md-6 col-xs-12">
          <div class="form-group">
            <form-input [id]="'idInputBarrioAc'" label="Barrio" [required]="false" placeholder="Barrio"
              [controlName]="'barrioAc'">
            </form-input>
          </div>
        </div>

        <div class="col-xl-4 col-md-6 col-xs-12">
          <div class="form-group">
            <form-select [id]="'idInputGenero'" [required]="false" helper="" label="Género" placeholder="Seleccione..."
              [dataList]="genders" [controlName]="'generoAc'" [idField]="'value'" [valueField]="'gender'">
            </form-select>
          </div>
        </div>

      </div>

      <div class="settings_btn mt-4 mb-4">

        <custom-buttons *ngIf="!acudienteConfirmar" [rounded]="true" text="Confirmar datos del Acudiente"
          title="Confirmar" icon="ti-check ml-2" type="button" (click)="confirmarAcudiente(acudienteForm)" />

        <custom-buttons *ngIf="acudienteConfirmar" [rounded]="true" [inputBackgroundColor]="'danger'"
          text="Cancelar confirmacion Acudiente" title="Cancelar" icon="ti-close ml-2" type="button"
          (click)="cancelarAcudiente()" />

      </div>
    </form>
  </ng-container>
</ng-template>

<ng-template #acudienteExistView>
  <div id="idAcudienteExist" class="animated fadeIn mt-4">
    <div class="user_exist">
      <div class="row" style="display: contents;">
        <div class="col-md-6 col-xs-12">
          <img *ngIf="acudiente.avatar" [src]="acudiente.avatar|getImage" style="height: 70px;" alt="">
          <span *ngIf="acudiente.names">{{acudiente.names}} </span>
          <span *ngIf="acudiente.lastNames">{{acudiente.lastNames}}</span>
          <span *ngIf="edadActualAcudiente && edadActualAcudiente < 18" class="menor_edad"
            title="El acudiente debe ser mayor de edad">Menor de Edad </span>
        </div>
        <div class="col-md-6 col-xs-12">
          <span *ngIf="edadActualAcudiente && edadActualAcudiente >= 18 && acudienteExistForm">
            <form [formGroup]="acudienteExistForm">
              <div class="form-group">
                <form-select [id]="'idInputRelationship'"
                  helper="Seleccione de la lista un parentesco del acudiente con el paciente"
                  label="Parentesco con el paciente" placeholder="Seleccione..." [dataList]="relationShips"
                  [controlName]="'relationship'" [idField]="'value'" [valueField]="'name'">
                </form-select>
              </div>
            </form>
          </span>
        </div>
      </div>
      <div>

      </div>
    </div>
    <div class="settings_btn mt-4 mb-4">

      <custom-buttons *ngIf="!acudienteExisteConfirmar && edadActualAcudiente && edadActualAcudiente>=18"
        [rounded]="true" text="Confirmar Acudiente" title="Confirmar" icon="ti-check ml-2" type="button"
        (click)="confirmarAcudienteExiste()" />

      <custom-buttons *ngIf="acudienteExisteConfirmar" [rounded]="true" [inputBackgroundColor]="'danger'"
        text="Cancelar confirmacion Acudiente" title="Cancelar" icon="ti-close ml-2" type="button"
        (click)="cancelarAcudienteExiste()" />

      <custom-buttons *ngIf="edadActualAcudiente && edadActualAcudiente<18" [rounded]="true"
        [inputBackgroundColor]="'danger'" text="Cancelar confirmacion Acudiente" title="Cancelar" icon="ti-close ml-2"
        type="button" (click)="cancelarAcudienteExiste()" />

    </div>
  </div>
</ng-template>

<ng-template #petsFormView>
  <div *ngIf="pets && pets.length>0" class="mb-4" style="display: flex; align-items: center;"
    (click)="currentPetsView = petsExistView">
    <custom-buttons type="button" class="mr-2 animated fadeIn" title="Regresar" textColor="#fff" [title]="''"
      icon="ti ti-arrow-left">
      <i class="ti ti-arrow-left"></i>
    </custom-buttons>
    <span class="fw_500 pointer">Ver Mascotas registradas</span>
  </div>
  <form id="idFormPet" [formGroup]="petForm!">
    <div class="row">
      <div class="col-xl-4 col-md-6 col-xs-12">
        <div class="form-group">
          <form-input [id]="'idInputPetName'" helper="" label="Nombre de la Mascota" placeholder="ejm: Toby"
            [controlName]="'petName'">
          </form-input>
        </div>
      </div>
      <div class="col-xl-4 col-md-6 col-xs-12">
        <div class="form-group">
          <form-input [id]="'idInputSpecies'" helper="" label="Especie de la Mascota"
            placeholder="ejm: Perro, Gato, Pez, etc..." [controlName]="'species'">
          </form-input>
        </div>
      </div>
      <div class="col-xl-4 col-md-6 col-xs-12">
        <div class="form-group">
          <form-input [id]="'idInputBreed'" helper="" label="Raza de la Mascota"
            placeholder="ejm: Bulldog, Persa, etc..." [controlName]="'breed'">
          </form-input>
        </div>
      </div>
      <div class="col-xl-4 col-md-6 col-xs-12">
        <div class="form-group">
          <form-select [id]="'idInputGender'" helper="Selecciona un genero de la lista" label="Género"
            placeholder="Seleccione..." [dataList]="gendersPets" [controlName]="'gender'" [idField]="'value'"
            [valueField]="'name'">
          </form-select>
        </div>
      </div>
      <div class="col-xl-4 col-md-6 col-xs-12">
        <div class="form-group">
          <form-input [id]="'idInputColor'" helper="" label="Color de la Mascota"
            placeholder="ejm: Blanco con manchas grises, etc..." [controlName]="'color'">
          </form-input>
        </div>
      </div>
      <div class="col-xl-4 col-md-6 col-xs-12">
        <div class="form-group">
          <form-select [id]="'idInputSterilized'" helper="Selecciona un estado de la lista" label="Fue Esterilizado/a?"
            placeholder="Seleccione..." [dataList]="sterilizationStates" [controlName]="'sterilized'"
            [idField]="'value'" [valueField]="'name'">
          </form-select>
        </div>
      </div>
      <div class="col-xl-4 col-md-6 col-xs-12">
        <div class="form-group">
          <form-date [id]="'idInputBirth_date'" label="Fecha de Nacimiento (Aprox.)" [maxInput]="fechaHoy"
            [controlName]="'birth_date'">
          </form-date>
        </div>
      </div>
      <div class="col-xl-4 col-md-6 col-xs-12">
        <div class="form-group">
          <form-input [id]="'idInputAge'" helper="" [type]="'number'" [required]="false"
            label="Edad Actual (Aproximada)" placeholder="Edad en años" [controlName]="'age'">
          </form-input>
        </div>
      </div>
    </div>
    <div class="settings_btn" style="margin-top: 40px;">
      <custom-buttons *ngIf="!petsConfirmar" text="Confirmar datos de la mascota" title="Confirmar" icon="ti-plus ml-2"
        type="button" [rounded]="true" (click)="confirmarPetForm()" />

      <custom-buttons *ngIf="petsConfirmar" [rounded]="true" [inputBackgroundColor]="'danger'"
        text="Cancelar la confirmacion" title="Cancelar" icon="ti-close ml-2" type="button"
        (click)="cancelarPetForm()" />
    </div>
  </form>
</ng-template>

<ng-template #petsExistView>
  <div *ngIf="!petSelected">
    <div class="row mt-4">
      <div id="idPetsList" class="col-12 txt_title_form">
        Seleccione una mascota para asignar una cita
      </div>
      <div class="col-xl-6 col-md-6 col-xs-12 fw_500 align_start">Mascotas registradas {{pets!.length}}</div>
      <custom-buttons class="col-xl-6 col-md-6 col-xs-12 align_end" text="Agregar nueva mascota"
        title="Permite agregar una mascota" textColor="#fff" type="button" icon="fa fa-plus" width="250px"
        (click)="currentPetsView = petsFormView" />
      <br />
      <div class="container-cards-desktop animated fadeIn">
        <app-card-subservice *ngFor="let pet of pets" [infoPet]="pet" caso="familyGroupPet" [viewOptions]="false"
          (selectPetAction)="petSelectedEmitter($event)">
        </app-card-subservice>
      </div>
    </div>
  </div>

  <div *ngIf="petSelected">
    <div class="user_exist">
      <img *ngIf="petSelected.avatar" [src]="petSelected.avatar|getImage" style="height: 70px;" alt="">
      <div style="margin-left: 10px;"><span *ngIf="petSelected.petName">{{petSelected.petName}} </span></div>
    </div>
    <div class="settings_btn" style="margin-top: 40px;">

      <custom-buttons *ngIf="!petExisteConfirmar" [rounded]="true" text="Confirmar la mascota" title="Confirmar"
        icon="ti-check ml-2" type="button" (click)="confirmarPetexiste()" />

      <custom-buttons *ngIf="petExisteConfirmar" [rounded]="true" [inputBackgroundColor]="'danger'"
        text="Cancelar la confirmacion" title="Cancelar" icon="ti-close ml-2" type="button"
        (click)="cancelarPetSelected()" />

    </div>

  </div>
</ng-template>
