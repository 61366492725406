import { EventEmitter, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ChangePassword, ChangePasswordData, GenerateVerificationCode } from '@interfaces/auth.interface';
import { LocalStorageService } from '@services';
import { map } from 'rxjs/operators';
import { ApiService } from 'src/app/services/api.service';
import { SweetAlertService } from 'src/app/services/sweet-alert.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  authChange: EventEmitter<any> = new EventEmitter<boolean>();

  constructor(
    private apiService: ApiService,
    private swalService: SweetAlertService,
    private router: Router,
    private localStorage: LocalStorageService
  ) {}


  //Login Original

    login(user: string) {
    return this.apiService.postRequest('login', { user }).pipe(
      map((res: any) => {
        if (res.ok) {

          const sessionNoRedirect = this.localStorage.getItem('pruebaKey');
          // Si no existe esa key en el localStorage al momemto del dispath no redirecciona al user/home pero inicia la session y
          // la mantiene en localStorage
          if(!sessionNoRedirect){
            this.swalService.showSuccess('Inicio de sesión', 'Bienvenido a Descuentos Médicos.');

            this.router.navigate(['/home']);
          }
          this.swalService.showSuccess('Inicio de sesión', 'Bienvenido a Descuentos Médicos.');

        } else this.swalService.lauchSwal('', res.message, 'warning');

        return res;
      })
    );
  }



  // ======================================
  // Get llave Pública para encriptar
  // ======================================
  register(user: any) {
    return this.apiService.postRequest('register', user).pipe(
      map((res: any) => {
        if (res.ok) {
          this.swalService.showSuccess('Inicio de sesión', 'Bienvenido a Descuentos Médicos.');

        } else {
        this.swalService.lauchSwal('', res.message, 'warning');
        }

        return res;
      })
    );
  }

  /**
   * Generar codigo de confirmacion para restablecer la contraseña dependiendo el caso
   * @param  {any} body caso : "correo" | "sms" | "whatsapp" , numeroDocumento, telefono, email
   */
  public generateVerificationCode(data: ChangePasswordData | GenerateVerificationCode) {
    return this.apiService.postRequest('user/verification-code', data);
  }

  /**
   * Cambiar contraseña del usuario validando _id y codigo de verificacion
   * @param  {any} body _id, codigo, contrasena
   */
  public changePassword(body: ChangePassword) {
    return this.apiService.postRequest('user/change-password', body).pipe(
      map((res: any) => {
        if (res.ok) {
          this.swalService.lauchSwal('', res.message, 'success');
        } else this.swalService.lauchSwal('', res.message, 'warning');

        return res;
      })
    );
  }


  // Verifica si la session y logeo esta iniciado para validar el header
  checkAuthentication() {
    const usdt = localStorage.getItem('usdt');
    const tkn = localStorage.getItem('tkn');
    const session = localStorage.getItem('session');
    const isAuthenticated = usdt && tkn && session;
    this.authChange.emit(isAuthenticated);
  }
}
