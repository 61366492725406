import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, Input, OnInit, QueryList, ViewChildren } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AppointmentTemp } from '@interfaces/appointments';
import { InformationService, ModalService, SocketsService, SweetAlertService, UserService } from '@services';
import { catchError, of } from 'rxjs';
import { TempService } from 'src/app/services/temp-service.service';
import { branches } from '../../provider-shared/components/state/branches.selectors';
import { log } from 'util';

@Component({
  selector: 'app-verify-code',
  templateUrl: './verify-code.component.html',
  styleUrls: ['./verify-code.component.scss']
})


export class VerifyCodeComponent implements OnInit {

  // data que se recibe del padre 
  @Input() data!: AppointmentTemp;
  sendCode!: AppointmentTemp;
  tempState: boolean = false;
  formVerify!: FormGroup
  buttonDisable: boolean = true;
  timeOnline: string = '0:00';
  @ViewChildren('inputControl') inputControls!: QueryList<ElementRef>;
  documentSet: string = '';

  constructor(private tempService: TempService,
    private userService: UserService,
    private information: InformationService,
    private modalService: ModalService,
    private router: Router,
    private swalService: SweetAlertService,
    private socket: SocketsService,
  ) {

    // Nos suscribimos al valor que manda el temporizador en el subject
    this.tempService.onTimerTick.subscribe((time: string) => {
      // Esta es la variable que renderiza el tiempo
      this.timeOnline = time;
    });

    // Esto es cuando el temporizador llega a cero
    this.tempService.onTimerEnd.subscribe(() => {
      this.onTimerEnd();
    })

  }


  ngOnInit(): void {
    this.inicializarFormVerify();
    this.initTemp();
    if (this.data) {
      this.sendCode = this.data;
      this.documentSet = this.sendCode.numeroDocumento;
      // console.log('data que se recibe del padre', this.sendCode);
      // console.log('numero de documento', this.documentSet);
      
    }
  }


  inicializarFormVerify() {

    this.formVerify = new FormGroup({
      controlOne: new FormControl(null, Validators.required),
      controlTwo: new FormControl(null, Validators.required),
      controlThree: new FormControl(null, Validators.required),
      controlFour: new FormControl(null, Validators.required),
      // controlFive: new FormControl(null, Validators.required),
      // controlSix: new FormControl(null, Validators.required)
    })

  }

  // Pasamos como parametros el evento del input y ademas de un index estatico
  onInputChange(event: any, index: number) {
    const input = event.target as HTMLInputElement;
    if (input.value.length === 1 && index < this.inputControls.length - 1) {
      // Si el input tiene 1 caracter y no es el último hace focus al  siguiente input  
      this.inputControls.toArray()[index + 1].nativeElement.focus();
    }
  }

  // Maneja la tecla de borar para pasar al input anterior
  onKeyDown(event: KeyboardEvent, index: number) {
    const input = event.target as HTMLInputElement;
    // Detecta la tecla y si el input está vacío pasa al campo anterior
    if (event.key === 'Backspace' && input.value.length === 0 && index > 0) {
      this.inputControls.toArray()[index - 1].nativeElement.focus();
    }
  }

  // Metodo para inicar el temporizador en 3 minutos 
  initTemp() {
    this.tempService.startTimer(3);
  }

  // Metodo para resetar el temporizador
  resetTemp() {
    this.tempService.resetTimer();
  }

  // Cuando el temporizador llega a cero
  private onTimerEnd(): void {
    // console.log('el tiempo se acabo');
    // Habilitar el reenvio del codigo
    this.tempState = true;
  }

  // Metodo para reenviar el codigo 
  resendCode() {
    // Enviar el codigo
    this.userService.postAppointmentTemp(this.sendCode).then(resp => {
      if (resp) {
        // Mostrar el reenvio
        this.tempState = false;
        // Reinciar contador
        this.initTemp();
        // Resetear el form
        this.formVerify.reset();
        // console.log('respues despues del reenvio', resp);
      }
    })
  }


  validatedCode() {
    if (this.formVerify.valid) {
      // console.log('valido y ejecuta accion');
      // Obtener los valores de los inputs y concactenarlos en una variable
      let valueForm = this.formVerify.value;
      // console.log('valor del form', valueForm);
      const { controlOne, controlTwo, controlThree, controlFour } = valueForm;
      // Concatenar todo el codigo en una variable
      const code = controlOne + controlTwo + controlThree + controlFour;

      // console.log('codigo', code);
      try {
        // Pasar como parametro la variable en el servicio de comfirmar temporal ademas del documento
        this.information.getRequestTemp(this.documentSet, code).pipe(
          // Manejar los posibles errores  :3
          catchError((error: HttpErrorResponse) => {
            
            // console.log('error que lanzo', error);
            
            let message = error.error.message;
            
           if(error.ok === false){
            // Cerramos el componente del modal
            // this.modalService.closeModal();
            // Informamos el error
            // this.swalService.lauchSwal(
            //   'Ha ocurrido un error',
            //    message,
            //   'error'
            // )
            
            this.swalService.showError('',message);

            // console.log('error peke');
            
            // Resetear el formulario
            this.formVerify.reset();
           }
            
            // Retornar un observable vacío o un valor por defecto
            return of(null);
          })
        ).subscribe(resp => {
          // console.log('respuesta despues del codigo', resp);
          // Si fue exitoso entonces cerramos el modal y redirigimos
          if(resp?.ok){
            // Se reinicia el temporizador 0:00
            // console.log('respuesta', resp);
            
            this.resetTemp();
            // Cerramos el modal
            this.modalService.closeModal();
            // Se navega al inicio
            this.router.navigate(['']);
            // Se indica el status positivo de la cita
            // this.swalService.lauchSwal(
            //   'Cita agendada correctamente',
            //   '',
            //   'success'
            // )
            this.swalService.showSuccess('','Cita agendada correctamente.')
            // Enviar el socket de la cita
            this.socket.emit('verifyTemporalAppointments', {
              branchId:  resp.body.branchId
            } 
              );
          }

          
        })
      } catch (error) {
        // Manejar el error en un swal
        // this.swalService.lauchSwal(
        //   'Ha ocurrido un error',
        //   'Intentalo de nuevo',
        //   error
        // );
        this.swalService.showError('','Ha ocurrido un error.')
        // this.router.navigate(['']);
        // console.log('error del try catch', error);
        
      }


    } else {
      this.formVerify.markAllAsTouched();
    }

  }
}