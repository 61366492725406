import { Injectable } from '@angular/core';
import { SwalOptions } from '@interfaces/sweet.alert.interface';
import Swal, { SweetAlertIcon } from 'sweetalert2';

@Injectable({
  providedIn: 'root',
})

/**
 * Servicio para mostrar ventanas emergentes de SweetAlert.
 *
 * Este servicio proporciona métodos para mostrar ventanas emergentes de SweetAlert
 * con diferentes tipos de mensajes como éxito, error, advertencia, información y pregunta.
 */
export class SweetAlertService {
  constructor() {}

  /**
   * Muestra una ventana emergente de SweetAlert con el título, texto y tipo de mensaje especificados.
   *
   * @param title - El título de la ventana emergente.
   * @param text - El texto de la ventana emergente.
   * @param type - El tipo de mensaje ('success', 'error', 'warning', 'info' o 'question').
   * @returns void
   */
  lauchSwal(title: string, text: string, type: any): void {
    Swal.fire(title, text, type);
  }

  /**
   * Muestra una alerta Swal con el título, texto y tipo especificados de manera asíncrona.
   *
   * @param {string} title - El título de la alerta.
   * @param {string} text - El texto de la alerta.
   * @param {any} type - El tipo de alerta (success, error, warning, info).
   * @returns {Promise<any>} - Una promesa que se resuelve con el resultado de la alerta Swal.
   */
  lauchSwalAsync(title: string, text: string, type: any) {
    return Swal.fire(title, text, type);
  }

  /**
   * Muestra una alerta Swal con la configuración especificada en un objeto.
   *
   * @param {object} objeto - El objeto que contiene la configuración para la alerta Swal.
   */
  lauchSwalObj(objeto: object): void {
    Swal.fire(objeto);
  }

  /**
   * Muestra una alerta Swal de confirmación con opciones personalizables.
   *
   * @param {SwalOptions} options - Los valores para inicializar el swal.
   * @returns {Promise<boolean>} - Una promesa que se resuelve con `true` si se confirma, o `false` si se cancela.
   */
  async launchConfirmSwal(options: SwalOptions) {
    const {
      title,
      text,
      icon,
      confirmButtonColor,
      cancelButtonColor,
      confirmButtonText,
      cancelButtonText,
    } = options;
    const action = await Swal.fire({
      title,
      text,
      icon,
      showCancelButton: true,
      confirmButtonColor: confirmButtonColor ?? 'var(--primary)',
      cancelButtonColor: cancelButtonColor ?? '#d33',
      confirmButtonText: confirmButtonText ?? 'Enviar',
      cancelButtonText: cancelButtonText ?? 'Cancelar',
    });

    if (action.isConfirmed) return true;

    return false;
  }

  /**
   * Muestra una ventana emergente de SweetAlert con un titulo por un tiempo y se cierra solo
   *
   * @param title - El título de la ventana emergente.
   * @returns void
   */
  lauchSwalTimer(title: string): void {
    Swal.fire({
      position: 'center',
      icon: 'success',
      title,
      showConfirmButton: false,
      timer: 1500,
    });
  }

  /**
   * Muestra un modal de SweetAlert con opciones seleccionables.
   *
   * Este método despliega un modal utilizando SweetAlert que permite al usuario seleccionar
   * una opción de un `select` desplegable. El usuario debe confirmar su selección antes de que
   * se ejecute la función de callback proporcionada. Este método es flexible y puede ser utilizado
   * en diferentes contextos donde se requiera que el usuario elija una opción de una lista personalizada.
   *
   * @param {string} title - El título que se mostrará en el modal.
   * @param {string} subtitle - El subtítulo o texto adicional que se mostrará debajo del título en el modal.
   * @param {string} confirmButtonText - El texto que se mostrará en el botón de confirmación. Por defecto es 'Confirmar'.
   * @param {string} cancelButtonText - El texto que se mostrará en el botón de cancelación. Por defecto es 'Regresar'.
   * @param {function} callback - La función a ejecutar cuando se confirma la acción. Esta función recibe
   *                              un parámetro: la opción seleccionada por el usuario.
   * @param {Array<{ value: string; text: string }>} options - Una lista de objetos que representa las opciones
   *                                                          disponibles en el `select`. Cada objeto debe
   *                                                          incluir un valor y un texto para mostrar en el `select`.
   * @param {Object} [buttonClass={}] - Un objeto opcional que contiene las clases CSS personalizadas para los botones.
   *                                    Puede incluir `confirmButtonClass` y `cancelButtonClass`.
   * @returns {Promise<void>} Una promesa que se resuelve cuando el proceso se completa o es cancelado por el usuario.
   */
  async showOptionsModal(
    title: string,
    subtitle: string,
    confirmButtonText: string = 'Confirmar',
    cancelButtonText: string = 'Regresar',
    callback: (selectedOption: string) => void,
    options: { value: string; text: string }[],
    buttonClass: any = {}
  ): Promise<void> {
    let data = await Swal.fire({
      title: title,
      icon: 'warning',
      html: `
            <p>${subtitle}</p>
            <br>
            <select class="form-control selectpicker show-tick" id="lista" data-style="btn-warning" data-live-search="true">
                <option value="-1" selected>Seleccione una opción</option>
                ${options
                  .map(
                    (option) =>
                      `<option value="${option.value}">${option.text}</option>`
                  )
                  .join('')}
            </select>
            <br>`,
      showCancelButton: true,
      focusConfirm: false,
      customClass: {
        confirmButton: buttonClass.confirmButtonClass || 'swal2-confirm',
        cancelButton: buttonClass.cancelButtonClass || 'swal2-cancel',
      },
      confirmButtonText: confirmButtonText,
      cancelButtonText: cancelButtonText,
      didOpen: async () => {
        Swal.showLoading();
        Swal.hideLoading();
      },
      preConfirm: () => {
        try {
          const selectElement = document.getElementById(
            'lista'
          ) as HTMLSelectElement;
          const selectedValue = selectElement?.value;

          if (!selectedValue || selectedValue === '-1') {
            throw new Error('Tienes que seleccionar una opción válida');
          }

          return { lista: selectedValue };
        } catch (error) {
          Swal.showValidationMessage((error as Error).message);
          return null;
        }
      },
    });

    if (data.value) {
      const selectedOption = data.value.lista;
      callback(selectedOption);
    }
  }

  // ###########################################################################################
  // swall de Diego    #########################################################################
  // ###########################################################################################

  /**
   * Basic  modal dialog
   * @param _title Title to show on modal dialog
   * @param _text Text to  show on modal dialog
   * @param _type Icon type to  show on modal dialog
   */
  swal(_title: string, _text: string, _type: any): void {
    Swal.fire(_title, _text, _type);
  }

  /**
   * Dialog modal to reload page by user
   */
  swalReloadConfirm(): void {
    Swal.fire({
      title: 'Error Inesperado',
      html: 'Lo sentimos, parece que ha ocurrido un problema. Por favor, intente recargar la página para solucionarlo.',
      icon: 'info',
      showCancelButton: false,
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Recargar',
    }).then((result) => {
      result.isConfirmed && window.location.reload();
    });
  }

  /**
   * Dialog modal to show and ask to user
   * @param _title Title to show on  dialog modal
   * @param _text Text to describe and show on dialog modal
   * @param icon icon to show on dialog, the options are: 'warning', 'error', 'success', 'info' and 'question'
   * @returns Promise with boolean 'True' or 'False', according to selection user
   */
  swalConfirm(
    _title: string,
    _text: string,
    icon: SweetAlertIcon,
    args?: { confirmButtonText?: string; cancelButtonText?: string }
  ) {
    return new Promise((resolve, reject) => {
      Swal.fire({
        title: _title,
        html: _text,
        icon: icon,
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: args?.confirmButtonText || 'Aceptar',
        cancelButtonText: args?.cancelButtonText || 'Cancelar',
      }).then((result) => {
        if (result.isConfirmed) {
          return resolve(true);
        } else if (result.isDenied) {
          Swal.fire('Changes are not saved', '', 'info');
        }
      });
    });
  }

  /**
   * Dialog modal to show and ask to user
   * @param _title Title to show on  dialog modal
   * @param _text Text to describe and show on dialog modal
   * @param icon icon to show on dialog, the options are: 'warning', 'error', 'success', 'info' and 'question'
   * @returns Promise with boolean 'True' or 'False', according to selection user
   */
  swalWaitConfirm(
    _title: string,
    _text: string,
    icon: SweetAlertIcon,
    args?: { confirmButtonText?: string; cancelButtonText?: string }
  ) {
    return new Promise((resolve, reject) => {
      Swal.fire({
        title: _title,
        html: _text,
        icon: icon,
        showCancelButton: false,
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Aceptar',
      }).then((result) => {
        console.debug(result);
        return resolve(true);
      });
    });
  }

  swalConfirmInfo(title: string, contentHtml: string) {
    return new Promise((resolve, reject) => {
      Swal.fire({
        title: `<strong> ${title} </strong>`,
        icon: 'info',
        html: contentHtml,
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Confirmar Registro',
        cancelButtonText: 'Cancelar',
      }).then((result) => {
        if (result.isConfirmed) {
          // Swal.fire(
          //   'Deleted!',
          //   'Your file has been deleted.',
          //   'success'
          // )
          return resolve(true);
        } else if (result.isDenied) {
          // Swal.fire('Changes are not saved', '', 'info')
          return reject(false);
        }
      });
    });
  }

  public swalInput(_title: string, _text: string, _entryPreview?: string) {
    return new Promise((resolve, reject) => {
      Swal.fire({
        title: _title,
        input: 'text',
        text: _text,
        inputAutoTrim: true,
        inputValue: _entryPreview,
        inputAttributes: {
          autocapitalize: 'off',
        },
        showCancelButton: true,
        confirmButtonText: 'Cambiar',
        showLoaderOnConfirm: true,
        preConfirm: (_currentEntry) => {
          if (_currentEntry !== '') return _currentEntry;
          Swal.showValidationMessage('El campo no puede ser vacío');
        },
      }).then((result) => result.isConfirmed && resolve(result.value));
    });
  }



  private showToast(icon: 'success' | 'info' | 'warning' | 'error', title: string, message: string, background: string, textColor: string) {
    Swal.fire({
      icon: icon,
      iconColor: background,
      // background: background,
      color: textColor, // Esto aplicará el color de texto al mensaje principal y otros textos
      // title: title,
      text: message,
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 5000,
      timerProgressBar: true,
      customClass: {
        title: 'swal-title-custom', // Añade clase personalizada para el título
      },
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer);
        toast.addEventListener('mouseleave', Swal.resumeTimer);
      }
    });
  }

  showSuccess(title: string, message: string) {
    this.showToast('success', title, message, '#00AB78', '#000');
  }

  showInfo(title: string, message: string) {
    this.showToast('info', title, message, '#058dd9', '#000');
  }

  showWarning(title: string, message: string) {
    this.showToast('warning', title, message, '#ffa14a', '#000');
  }

  showError(title: string, message: string) {
    this.showToast('error', title, message, '#e74d3ce6', '#000');
  }


  // public swalCounter(quantity: number, _timerInterval: any) {
  //   let timerInterval: any;
  //   let tmpQuantity = quantity - quantity;

  //   if (this.swalCounterLaunched) return;

  //   Swal.fire({
  //     title: 'Enviado campaña...',
  //     html: `Enviadas <b>0</b>  de ${quantity} campañas`,
  //     showCancelButton: true,
  //     customClass: {
  //       actions: 'timer_actions',
  //     },
  //     cancelButtonText: 'Parar envío',
  //     cancelButtonColor: 'danger',
  //     timer: _timerInterval * (quantity + 1),
  //     // timerProgressBar: true,
  //     didOpen: () => {
  //       Swal.showLoading();
  //       timerInterval = setInterval(() => {
  //         let content = Swal.getHtmlContainer();
  //         tmpQuantity += 1;
  //         if (content) {
  //           let b: any = content.querySelector('b');
  //           if (b) {
  //             b.textContent = tmpQuantity;
  //           }
  //         }
  //       }, _timerInterval);
  //     },
  //     willClose: () => {
  //       clearInterval(timerInterval);
  //     },
  //   }).then((result) => {
  //     /* Read more about handling dismissals below */
  //     if (result.dismiss === Swal.DismissReason.timer) {
  //       // console.log('I was closed by the timer')
  //     }
  //   });
  // }

  // public swalReconnection(onlineFlag: Observable<boolean>) {
  //   console.trace();
  //   let timerInterval: any;

  //   if (this.swalOfflineLaunched) return;
  //   let f = Swal.fire({
  //     title: 'Se ha perdido la conexión',
  //     html: '<b></b>',
  //     timer: 60000,
  //     timerProgressBar: true,
  //     didOpen: (d) => {
  //       Swal.showLoading();
  //       let pointCounter = 0;

  //       timerInterval = setInterval(() => {
  //         const b = Swal.getHtmlContainer();
  //         const time = Swal.getTimerLeft();
  //         pointCounter++;
  //         pointCounter = pointCounter > 3 ? 1 : pointCounter;
  //         if (b && time)
  //           b.textContent = `Reconectando${' .'.repeat(pointCounter)}`;
  //         // if (b && time) b.textContent = `Reconectando ${(((time - 60000) * -1) / 1000).toFixed(0)} seg`
  //       }, 500);
  //     },
  //     willClose: () => {
  //       clearInterval(timerInterval);
  //     },
  //   }).then((result) => {
  //     /* Read more about handling dismissals below */
  //     if (result.dismiss === Swal.DismissReason.timer) {
  //       console.log('I was closed by the timer');
  //     }
  //   });

  //   onlineFlag.subscribe((rs) => rs && Swal.close());
  // }

  // public swalWaitingInformation(
  //   triggerFlag: boolean,
  //   title: string,
  //   textContent: string,
  //   timeout: number = 300000
  // ) {
  //   // console.debug('swalWaitingInformation', triggerFlag, title, textContent, timeout);
  //   let timerInterval: any;

  //   if (triggerFlag && this.swalInformationLaunched) return;

  //   if (!triggerFlag) {
  //     this.swalInformationLaunched = false;
  //     Swal.close();
  //     return;
  //   }

  //   this.swalInformationLaunched = true;

  //   let f = Swal.fire({
  //     title,
  //     html: '<b></b>',
  //     timer: timeout,
  //     allowOutsideClick: false,
  //     timerProgressBar: true,
  //     didOpen: (d) => {
  //       Swal.showLoading();
  //       let pointCounter = 0;

  //       timerInterval = setInterval(() => {
  //         const b = Swal.getHtmlContainer();
  //         const time = Swal.getTimerLeft();
  //         pointCounter++;
  //         pointCounter = pointCounter > 3 ? 1 : pointCounter;
  //         if (b && time)
  //           b.textContent = `${textContent}${' .'.repeat(pointCounter)}`;
  //         // if (b && time) b.textContent = `Reconectando ${(((time - 60000) * -1) / 1000).toFixed(0)} seg`
  //       }, 500);
  //     },
  //     willClose: () => clearInterval(timerInterval),
  //   }).then((result) => {
  //     result.dismiss === Swal.DismissReason.timer && this.swalReloadConfirm();
  //   });
  // }
}
