<aside class="left-sidebar">
  <div class="scroll-sidebar">
    <nav class="sidebar-nav">
      <ul id="sidebarnav">
        <!-- User Profile Section -->
        <li class="user-profile">
          <a
            (click)="toggleUserProfile()"
            class="has-arrow waves-effect waves-dark img_perfil"
            href="javascript:void(0)"
          >
            <img [src]="rolSelect.avatar | getImage" alt="user" class="size_30" />
            <span class="hide-menu">{{ rolSelect.name }}</span>
          </a>

          <!-- Toggle User Profile -->
          <ul *ngIf="isUserProfileExpanded" class="collapse show overflow_oculto">
            <li>
              <a [routerLink]="getProfileRoleUrl" routerLinkActive="active">
                Mi perfil de {{ getProfileRoleName(rolSelect.rol) }}
              </a>
            </li>

            <li class="nav-devider"></li>

            <ng-container *ngIf="roles.length">
              <li class="user-profile" *ngFor="let rol of roles">
                <div
                  class="row hover_active sidebar-nav-item"
                  style="cursor: pointer"
                  (click)="changeRol(rol)"
                >
                  <div class="col-3">
                    <img class="avatar" [src]="rol.avatar | getImage" alt="user" />
                  </div>
                  <div class="col-9">
                    <h5 class="username">{{ rol.name }}</h5>
                    <p class="role">{{ getProfileRoleName(rol.rol) }}</p>
                  </div>
                </div>
              </li>
            </ng-container>
          </ul>
        </li>

        <li class="nav-devider nav_devider_100"></li>

        <!-- Dynamic Menu Section -->
        <ng-container *ngFor="let m of menuItems">
          <ng-container *ngIf="m.active">
            <li class="nav-small-cap">
              <span class="hide-menu">{{ m.module }}</span>
            </li>

            <li *ngFor="let o of m.options">
              <a
                (click)="toggleSubMenu(o.option)"
                class="has-arrow waves-effect waves-dark"
                href="javascript:void(0)"
              >
                <i
                  [class]="o.icon"
                  [ngClass]="{
                    icon_active: o.option === opcion,
                    icon_green: o.option !== opcion 
                  }"
                ></i>
                <span class="hide-menu">{{ o.option }}</span>
              </a>

              <!-- Toggle Submenu -->
              <ul *ngIf="expandedOption === o.option" class="collapse show">
                <li *ngFor="let sm of o.subMenu">
                  <a  *ngIf="sm.view"
                  [routerLink]="sm.route"
                  [ngClass]="{ 'active': selectedSubMenu === sm.name }"
                  (click)="selectSubMenu(sm.name)">
                    {{ sm.name }}
                  </a>
                </li>
              </ul>
            </li>
          </ng-container>
        </ng-container>
      </ul>
    </nav>
  </div>
</aside>
