import { Component, Input, OnInit, AfterViewInit, OnDestroy, TemplateRef, ViewChild, ChangeDetectorRef, ElementRef, } from '@angular/core';
import { ActivatedRoute, Router, UrlSegment } from '@angular/router';
import { Album, FamilyGroup, SubService } from '@interfaces/information.interface';
import { ImagesViewerComponent } from '../images-viewer/images-viewer.component';
import { ModalOptions } from '@interfaces/modal.reusable.interface';
import { ApplicationService, EncryptService, InformationService, LocalStorageService, ModalService, SweetAlertService, UserService } from '@services';
import { IStepper, StepperComponent } from '../schedule-programator/stepper/stepper.component';
import { AnimationOptions } from 'ngx-lottie';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { select, Store } from '@ngrx/store';
import { GoogleService } from 'src/app/auth/services/google.service';
import { AuthService } from 'src/app/auth/services/auth.service';
import { Appointment, AppointmentTemp, SendTicketAppointment } from '@interfaces/appointments';
import { Hour } from '@interfaces/appointments';
import { Observable, Subscription } from 'rxjs';

import { Location } from '@angular/common';
import { FamilyGroupAndBeneficiaryComponent } from '../family-group-and-beneficiary/family-group-and-beneficiary.component';
import { FamilyGroupAndPetComponent } from '../family-group-and-pet/family-group-and-pet.component';
import { FamilyGroupService } from 'src/app/services/familyGroupAndBeneficiary.service';
import { __values } from 'tslib';
import { memberId } from 'src/app/auth/state/auth.selectors';
import Swal from 'sweetalert2';
import html2canvas from 'html2canvas';
import { debounce, unset, values } from 'lodash';
import { VerifyCodeComponent } from '../verify-code/verify-code.component';
import { SmoothScrollService } from 'src/app/services/smooth-scroll.service';



@Component({
  selector: 'app-main-service-details',
  templateUrl: './main-service-details.component.html',
  styleUrls: ['./main-service-details.component.scss']
})
export class MainServiceDetailsComponent implements OnInit, AfterViewInit, OnDestroy {

  // DATA NECESARIO PARA ESTE COMPONENTE
  @Input() service: any;
  @Input() subServiceDetails!: SubService;
  @Input() infoBasicCompany: any = {};
  @Input() infoDetailsCompany: any = {};



  //TEMPLATES PARA LAS TABS
  @ViewChild('detallesSubServicio') detallesSubServicio!: TemplateRef<any>;
  @ViewChild('informacion') informacion!: TemplateRef<any>;
  @ViewChild('multimedia') multimedia!: TemplateRef<any>;
  @ViewChild('servicios') servicios!: TemplateRef<any>;
  @ViewChild('profesionales') profesionales!: TemplateRef<any>;
  @ViewChild('sucursales') sucursales!: TemplateRef<any>;

  isDetallesSubServicioActive: boolean = false; // Para condicionar el css del div company en mobile cuando se esta en la tab-detallesSubServicio


  //TEMPLATES PARA EL STEPPER
  @ViewChild(StepperComponent) stepperComponent!: StepperComponent; // COMPONENTE STEPPER
  @ViewChild('citaDetalles') citaDetalles!: TemplateRef<any>;
  @ViewChild('motivoCita') motivoCita!: TemplateRef<any>;
  @ViewChild('citaSucursal') citaSucursal!: TemplateRef<any>;
  @ViewChild('citaDoctor') citaDoctor!: TemplateRef<any>;
  @ViewChild('citaHorario') citaHorario!: TemplateRef<any>;
  @ViewChild('citaPaciente') citaPaciente!: TemplateRef<any>;
  @ViewChild('citaResumen') citaResumen!: TemplateRef<any>;

  //TEMPLATES PARA LOS TICKETS
  @ViewChild('canvasEl') canvasEl!: ElementRef<HTMLCanvasElement>;
  @ViewChild('canvasPets') canvasPets!: ElementRef<HTMLCanvasElement>;



  // Recuperar Contraseña
  @ViewChild('email') email!: TemplateRef<any>;
  @ViewChild('phone') phone!: TemplateRef<any>;
  @ViewChild('changePassword') changePassword!: TemplateRef<any>;

  stepperListCitaTemp: IStepper[] = [];
  stepperListCitaLogeado: IStepper[] = [];
  disableNext: boolean = false;
  disablePrev: boolean = false;

  initialStepsSessionLogged: number[] = [];
  initialStepsSessionNotLogged: number[] = [];





  // PARA PASARLE AL COMPONENTE  VIEW-APPOINTMENT
  optionsDetalles: AnimationOptions = {
    path: 'assets/lottie-animations/step-detalles.json'
  }
  textDetalles = 'Estas solicitando cita con los mejores descuentos, dale en siguiente para continuar con el proceso';

  optionsPaciente: AnimationOptions = {
    path: 'assets/lottie-animations/step-paciente_2.json',
  };
  textPaciente!: string;

  optionsCita: AnimationOptions = {
    path: 'assets/lottie-animations/heart.json',
  }
  // textCita = 'Con el proposito de tener informado al personal de la salud y dispuesto a tu servicio queremos saber el motivo de la cita';
  textCita = 'Con el proposito de tener informado al personal de la salud y dispuesto a tu servicio selecciona la dirección y profesional disponible para tu cita';

  optionsSucursal: AnimationOptions = {
    path: 'assets/lottie-animations/step-sucursal_2.json',
  }
  textSucursal = 'Selecciona la sucursal para solicitar tu cita, recuerda escoger la mas cercana';

  optionsDoctor: AnimationOptions = {
    path: 'assets/lottie-animations/step-doctor_2.json',
  }
  textDoctor = 'Selecciona el profesional de la salud para tu cita, recuerda que son profesionales verificados desde el Rethus';

  optionsCalendario: AnimationOptions = {
    path: 'assets/lottie-animations/calendar_edited.json',
  }
  textCalendario = 'Selecciona la fecha de tu cita, recuerda llegar puntual';

  optionsResumen: AnimationOptions = {
    path: 'assets/lottie-animations/step-resumen.json'
  }

  textResumen = 'Al finalizar puedes generar tu recibo de descuento, este recibo llegara a tu WhatsApp'


  // VARIABLES

  pruebaKey: string = 'pruebaKey';
  pruebaValue!: string;
  currentView: any;
  activeSubServices!: any;
  albumsCompany: Album[] = [];
  albumSelected?: Album | null;
  cityId!: string;
  paramIdCity!: string;
  paramIdService!: string;
  loginMoment: boolean = false;
  previousUrl!: string;
  authOK!: boolean;
  stepKey: string = 'step'
  componentWidthSubscription = new Subscription();
  componentWidth!: number;



  // DataStore

  branches: any;
  doctors: any;
  idSubservice!: string;
  idMunicipality!: string;
  nameSubservice!: string;
  infoUser: any;
  //infoUserPatiente: any;
  specialityOfPets!: boolean;
  specialityOfHumans!: boolean;
  selectDoctorID: string | null = null;
  defaultAvatar: string = 'assets/images/icon/photo_silhouette.png';

  // === Variables Step Motivo cita === //
  reasonAppointmentForm!: FormGroup;
  validTextArea: boolean = false;
  branchesSelect: any[] = [];
  doctorsSelect: any[] = [];
  branchText: string = '';
  doctorText: string = '';
  isSingleDoctorLogged!: boolean;
  branchsTotal: any[] = [];

  // === Variables Step Sucursal === //

  defaultCompany: string = 'assets/images/sucursal.webp';
  cityNameBranch: any;
  phoneBranch: any;

  selectBranchID: string | null = null;
  selectPacienteID: string | null = null;


  // === Variables Step horario (calendario) === //
  availableAppoiment!: boolean;
  selectCalendar: boolean = false;
  payload!: any;
  // Data que se le pasa al calendario.
  inputData = {
    subServiceId: '',
    doctorId: '',
    branchId: '',
  };

  // === Variables Step Paciente o Temp === //

  formTemp!: FormGroup;
  formValidateUser!: FormGroup;
  verified: boolean = true;
  viewOptions: boolean = false;
  num!: string;
  prefix!: string;
  flip: boolean = false;
  formOptionsCode!: FormGroup;

  // Variables para controlar la vista en verificar
  init: boolean = true;
  verifyStatus: boolean = false;
  codeSend: boolean = false;
  disableVerify: boolean = true;
  formCompleted: boolean = false;
  codeSelect = [
    { value: 'wsp', text: 'WhatsApp' },
    { value: 'sms', text: 'Mensaje de texto' }
  ];
  disableDocument: boolean = true;
  selectCode: string | null = null;


  // Pacientes
  viewContentPage: boolean = false;
  selectedFamilyGroup: any = null;
  petToFamilyGroupMap: any;

  // Variables de los metodos family groups
  dataFamilyGroupsAndBeneficiries: any;
  dataFamilyGroupsAndPets: any;
  userMoreLeader!: boolean;
  beneficiaryToFamilyGroupMap: any;
  dataFamilyGroups: any;
  dataUserLogeado: any;
  NotFoundGroupsFamily!: boolean; // condicionar el renderizado de si tiene o tiene beneficiarios
  gender: any;
  dataPetsGroup: any;
  surnamePet: string = '  ';

  // Variables para el ticket y su envio
  context!: CanvasRenderingContext2D;
  appointmentTicket: any;
  appointmentTicketResumen: any;
  aproxHour: any;
  priceTicket!: string;
  discountedPriceTicket!: string;

  // Cuerpo de la peticion para sacar la Cita Logeado
  appointmentTemp: AppointmentTemp = {
    appointmentDate: " ",
    hour: " ",
    shiftId: " ",
    branchId: " ",
    doctorId: " ",
    subServiceId: " ",
    nombres: " ",
    apellidos: " ",
    numeroDocumento: " ",
    tipoDocumento: " ",
    telefono: " ",
    appointmentReason: " ",
    office: " ",
    patientType: " "
  }

  // Cuerpo de la peticion para sacar la Cita Logeado
  appointment: Appointment = {
    appointmentDate: '',
    hour: '',
    whoScheduled: '',
    shiftId: '',
    branchId: '',
    doctorId: '',
    subServiceId: '',
    appointmentReason: '',
    receiptNumber: '',
    office: '',
    patient: '',
    patientType: '',
    platform: "web"
  };

  // Cuerpo del Resumen de la cita
  appointmentResumen: any = {
    sucursal: ' ',
    consultorio: ' ',
    direccion: ' ',
    barrio: ' ',
    telefonos: ' ',
    fecha: ' ',
    hora: ' ',
    medico: {
      nombres: ' ',
    },
    paciente: {
      nombres: ' ',
      apellidos: ' ',
    },
    propietario: {
      nombre: '',
      apellidos: '',
    },
    cedulaPropietario: '',
    especie: '',
    raza: '',
    fechaNacimiento: '',
  };

  // nuevos objetos de info para la cita
  infoPatient: any = {};
  infoAppointment: any = {};
  infoPet: any = {};

  private subs = new Subscription();

  public params: any;
  public allServDetail: any;
  public infoCompany: any;
  public idCompany: any;
  public idSubService: any;
  // viewPatient: boolean = false;
  currentUrl!: string; // Usa Location y no Router


  constructor(private router: Router,
    private routeParam: ActivatedRoute,
    private location: Location,
    private modalService: ModalService,
    private cdr: ChangeDetectorRef,
    private infoService: InformationService,
    private store: Store,
    private swalService: SweetAlertService,
    private userService: UserService,
    private familyGroupService: FamilyGroupService,
    public appService: ApplicationService,
    private scrollService: SmoothScrollService


  ) {
    this.iniciarFormularioMotivoCita();
    //this.subscribeToRouterEvents();
  }



  ngOnInit(): void {
    // Obtener el id del user logeado para validarlo con el del doctor para no dejarlo solicitar cita para el mismo
    let userLogeado$ = this.store.pipe(select(memberId));
    userLogeado$.subscribe(data => {
      // // console.log('id del user logeado', data);
    })

    this.changeTab('detallesSubServicio'); // Iniciar con detallesSubServicio activo

    // OBTENER URL PARA VALIDAR DIRECCIONAMIENTO DEPENDIENDO EL CASO LOGEADO O NO DE LOS SUBSERVICIOS DE LA EMPRESA ACTUAL
    this.currentUrl = this.location.path();
    // METODO PARA ELIMINAR LOS PATH DE LOS STEP AL REINCIAR EL COMPONENTE
    // this.removeExtraPath();
    // // console.log('ruta actual', this.currentUrl);
    // console.log('Servicio', this.service);
    // // console.log('Detalles Subservicio', this.subServiceDetails);
    // // console.log('Detalles Basicos Company', this.infoBasicCompany);
    // // console.log('Detalles Company', this.infoDetailsCompany);
    this.cityId = this.infoDetailsCompany.company.municipality.cityId;
    // // console.log('id municipio', this.cityId);
    this.activeSubServices = this.infoDetailsCompany.activeSubServices;
    // // console.log('servicios activos', this.activeSubServices);
    this.doctors = this.infoDetailsCompany.doctors;
    // console.log('Doctores de la company', this.doctors);
    this.branches = this.infoDetailsCompany.branches;
    // // console.log('sucursales de la company', this.branches);
    this.branchsTotal = this.service.branches;
    // console.log('branches', this.branchsTotal);
    this.albumsCompany = this.infoDetailsCompany.albums;
    // // console.log('albums de la company', this.albumsCompany);
    this.notService();
    setTimeout(() => { this.currentView = this.detallesSubServicio; }, 100);
    // Obtener session del localStorage para validar la redireccion
    this.validatedSession();
    // if(this.loginMoment = true){
    // this.textPaciente  = 'Selecciona un paciente para solicitar tu cita, recuerda que puedes añadir nuevos grupos familiares, beneficiarios y mascotas';
    // }
    // if(this.loginMoment = false){
    //   this.textPaciente  = 'Completa el formulario con los datos del paciente que requiere la cita'
    // }

    this.appointment.subServiceId = this.service.subservice._id;
    this.appointmentTemp.subServiceId = this.service.subservice._id;
    this.inputData.subServiceId = this.idSubservice;

    this.selectPacienteID; // para inicializar el select del paciente del user logeado
    // this.selectDoctorID; // para inicializar el select del doctor de la cita
    // this.selectBranchID; // para inicializar el select de la sucursal de la cita
    // this.disableNext = false;

    //Añade el idSubservicio para enviar a calendario
    this.inputData.subServiceId = this.service.subservice._id;

    // Step Motivo de Cita
    // this.iniciarFormularioMotivoCita();
    this.verifyValueDataAppointmentReason();
    this.branchesSelect = this.transformBranchesToSelectOptions(this.branchsTotal);

    // Si solo hay una sucursal, seleccionarla por defecto
    if (this.branchesSelect.length === 1) {
      this.reasonAppointmentForm.get('branch')?.setValue(this.branchesSelect[0].value);
      this.updateDoctorsSelect(this.branchesSelect[0].value); // Cargar doctores de la sucursal seleccionada
    }

    // Escuchar cambios en la sucursal seleccionada para cargar doctores correspondientes
    this.reasonAppointmentForm.get('branch')?.valueChanges.subscribe(branchId => {
      this.updateDoctorsSelect(branchId); // Método que carga doctores según la sucursal
    });

    // Obtener el id del user logeado para validarlo con el del doctor
    // Obtener el id del user logeado para validarlo con el del doctor
    this.store.pipe(select(memberId)).subscribe(userId => {
      const branchId = this.reasonAppointmentForm.get('branch')?.value;

      // Verificar si hay un branchId seleccionado
      if (branchId) {
        // Obtener doctores de la sucursal seleccionada
        const selectedBranch = this.findBranchById(branchId, this.branchsTotal);

        if (selectedBranch) {
          const filteredDoctors = selectedBranch.doctors.filter((doctor: any) => doctor._id !== userId);

          // Verificar si el único doctor es el usuario logueado
          if (filteredDoctors.length === 0 && selectedBranch.doctors.length === 1) {
            this.isSingleDoctorLogged = true;

            // Mostrar el Swal si el único doctor es el usuario logueado
            return Swal.fire({
              icon: 'info',
              text: 'No puedes solicitar cita para ti mismo',
              confirmButtonColor: '#00ab78',
              confirmButtonText: 'Ok',
              cancelButtonColor: '#d33',
              cancelButtonText: 'No',
              allowOutsideClick: false,
            }).then((result) => {
              if (result.isConfirmed) {
                this.router.navigate(['/home']);
              }
            });
          } else {
            this.isSingleDoctorLogged = false;
          }

          // Transformar doctores filtrados a opciones del select
          this.doctorsSelect = this.transformDoctorsToSelectOptions(filteredDoctors);

          // Seleccionar automáticamente si solo queda un doctor después del filtrado
          if (this.doctorsSelect.length === 1) {
            this.reasonAppointmentForm.get('doctor')?.setValue(this.doctorsSelect[0].value);
          }
        }
      }

      // Asegurarse de devolver algo en todas las rutas de código
      return;
    });

    // Verificar cambios en el formulario para actualizar el estado del botón "Siguiente"
    this.reasonAppointmentForm.valueChanges.subscribe(() => {
      this.updateNextButtonState();
    });

    // Verificar el estado del botón al inicializar
    this.updateNextButtonState();

    // TEMP
    this.iniciarFormularioValidarUser()
    this.iniciarFormularioTemp();



    // Despues de agregar un nuevo grupo familiar y beneficario o mascota desde el modal, se actualiza los grupos familiares de nuevo
    this.subs.add(
      this.familyGroupService.familyGroupUpdated$.subscribe(() => {
        this.validatedSession();
        // // console.log('se agrego el nuevo grupo familiar');
        // this.disableNext = false;
      })
    );

  }

  ngAfterViewInit(): void {

    // Calcular el ancho de la vista o componente
    this.modalService.observeModalWidth('main-container');

    this.componentWidthSubscription.add(this.modalService.width$.subscribe(width => {
      this.componentWidth = width;
      // console.log('ancho del componente', this.componentWidth);
    }))

    setTimeout(() => {

      let dateAppointment = 'date-appointment';
      this.addNewPath(dateAppointment);
      // Stepper temp
      this.stepperListCitaTemp = [

        {
          index: 1,
          label: 'Datos  Cita',
          contentTemplate: this.motivoCita,
        },

        {
          index: 2,
          label: 'Horario',
          contentTemplate: this.citaHorario,
        },
        {
          index: 3,
          label: 'Paciente',
          contentTemplate: this.citaPaciente,
        },

      ]

      // Stepper logeado
      this.stepperListCitaLogeado = [

        {
          index: 1,
          label: 'Datos  Cita',
          contentTemplate: this.motivoCita,
        },

        {
          index: 2,
          label: 'Horario',
          contentTemplate: this.citaHorario,
        },
        {
          index: 3,
          label: 'Paciente',
          contentTemplate: this.citaPaciente,
        },
        {
          index: 4,
          label: 'Resumen',
          contentTemplate: this.citaResumen,
        },
      ]

      this.cdr.detectChanges();
      // // console.log('stepper list', this.stepperListCita);
    }, 500);


  }

  // Obtener info de un usuario por su id
  getInfoUser(id: string): Observable<any> {
    const infUserObservable = this.infoService.getInfoUser(id);
    // // console.log('info User', infUserObservable);
    return infUserObservable;
  }

  // METODO PARA CAMBIAR DE VISTA
  changeTab(tab: string) {
    this.isDetallesSubServicioActive = (tab === 'detallesSubServicio');

    switch (tab) {
      case 'detallesSubServicio':
        this.currentView = this.detallesSubServicio;
        this.disableNext = true;
        break;
      case 'informacion':
        this.currentView = this.informacion;
        break;
      case 'multimedia':
        this.currentView = this.multimedia;
        break;
      case 'servicios':
        this.currentView = this.servicios;
        break;
      case 'profesionales':
        this.currentView = this.profesionales;
        break;
      case 'sucursales':
        this.currentView = this.sucursales;
        break;
    }

    // Actualiza el estado del botón al cambiar de pestaña
    if (tab === 'detallesSubServicio') {
      this.updateNextButtonState();
    }
  }


  // METODO PARA NO MOSTRAR EL SERVICO ACTUAL EN LA TAB SERVICIOS
  notService() {
    this.routeParam.queryParams.subscribe((params) => {
      this.paramIdCity = params['mun'];
      // // console.log('id ciudad servicio actual', this.paramIdCity);
      this.paramIdService = params['sub'];
      // // console.log('id servicio actual', this.paramIdService);
      // Filtrar el array para eliminar el subService cuyo _id coincide con paramIdService osea con el servicio actual
      if (this.activeSubServices && this.activeSubServices.length) {
        this.activeSubServices = this.activeSubServices.filter((service: any) => service.subService._id !== this.paramIdService);
        // // console.log('array despues eliminar el servicio actual', this.activeSubServices);
      }
    });
  }

  // VALIDAR LA REDIRECCION CUANDO SE ESTA LOGEADO O NO
  emitDataSubs(serviceId: any, cityId: any) {

    if (this.loginMoment === false) {
      this.router
        .navigate(['/directory/directory-details'], {
          queryParams: { mun: cityId, sub: serviceId },
        })
        .then(() => {
          // Recargar la página después de la navegación
          window.location.reload();
          // // console.log('se ejecuto desde el padre la funcion y reload');
        });
    }

    // Cuando el user esta solicitando cita desde directory
    if (this.loginMoment === true && this.currentUrl.includes('/directory/directory-details')) {
      const urlSegments = this.currentUrl.split('?');
      const basePath = urlSegments[0];
      const queryParams = urlSegments[1]
        ? urlSegments[1].split('&').reduce((acc: { [key: string]: string }, current) => {
          const [key, value] = current.split('=');
          acc[key] = value;
          return acc;
        }, {})
        : {};
      // // console.log('base path', basePath);
      // // console.log('queryparamas', queryParams);

      this.router.navigate([basePath], { queryParams: { mun: cityId, sub: serviceId } })

        .then(() => {
          // Recargar la página después de la navegación
          window.location.reload();
          // // console.log('Se ejecutó desde el padre la función y reload');
        });

    }

    if (this.loginMoment && !this.currentUrl.includes('/directory/directory-details')) {
      this.router
        .navigate(['/user/detalles-servicio'], {
          queryParams: { mun: cityId, sub: serviceId },
        })
        .then(() => {
          // Recargar la página después de la navegación
          window.location.reload();
          // // console.log('se ejecuto desde el padre la funcion y reload');
        });
    }
  }


  // METODO PARA CAMBIAR LOS EVENTOS DEL STEP EN TEMP
  onStepEventTemp(event: any) {
    // // console.log(event);

    if (event.index === 1) {
      let dateAppointment = 'step-date-appointment';
      this.addNewPath(dateAppointment);
      this.updateNextButtonState();
    }

    if (event.index === 2 && event.type === 'next') {
      let calendarPath = 'step-calendar';
      this.addNewPath(calendarPath);
      this.disableNext = true;
      if (this.appointmentTemp.shiftId.length > 3) { this.disableNext = false }
    }

    if (event.index === 2 && event.type === 'prev') {
      let calendarPath = 'step-calendar';
      this.addNewPath(calendarPath);
      this.disableNext = false;
      if (this.appointmentTemp.shiftId.length > 3) { this.disableNext = false }
    }

    if (event.index === 3) {
      let datePatient = 'step-date-patient';
      this.addNewPath(datePatient);
      this.disableNext = true;
      this.init = true;
      this.verifyStatus = false;
      this.codeSend = false;
      this.listenValueVerify();
      // this.formValidateUser.reset();
    }
    this.cdr.detectChanges();
  }
  // METODO PARA CAMBIAR LOS EVENTOS DEL STEP EN LOGEADO
  onStepEventLogeado(event: any) {
    // //console.log(event);

    if (event.index === 1) {
      this.disableNext = false;
      let dateAppointment = 'step-date-appointment';
      this.addNewPath(dateAppointment);
    }

    if (event.index === 2 && event.type === 'next') {
      let calendarPath = 'step-calendar';
      this.addNewPath(calendarPath);
      this.disableNext = true;
      if (this.appointment.shiftId.length > 3) { this.disableNext = false }
    }

    if (event.index === 2 && event.type === 'prev') {
      let calendarPath = 'step-calendar';
      this.addNewPath(calendarPath);
      this.disableNext = false;
      if (this.appointment.shiftId.length > 3) { this.disableNext = false }
    }

    if (event.index === 3) {
      let datePatient = 'step-date-patient';
      this.addNewPath(datePatient);
      this.disableNext = true;
      if (this.appointment.patient && this.appointment.patient.length > 0) this.disableNext = false;
    }

    if (event.index === 4) {
      let dateFinal = 'step-final-data';
      this.addNewPath(dateFinal);
      this.disableNext = false;
    }

    this.cdr.detectChanges();
  }

  // Obtiene datos de una compañia por el id
  async getDetailInfoCompany(id: string) {
    try {
      this.infoService.getDetailInfoCompany(id).subscribe((data) => {
        this.infoCompany = data;
        // // //console.log('data de la company', this.infoCompany);
      });
    } catch (error) {
      this.infoCompany = [];
    }
  }

  // METODO PARA VALIDAR LA SESSION
  validatedSession() {
    // Validamos el servicio
    this.validtedSubService();
    this.initialStepsSessionLogged = [];
    this.initialStepsSessionNotLogged = [];
    let session = localStorage.getItem('session');
    let tkn = localStorage.getItem('tkn');
    let usdt = localStorage.getItem('usdt');
    if (session && tkn && usdt) {
      this.loginMoment = true;
      this.textPaciente = 'Selecciona un paciente para solicitar tu cita, recuerda que puedes añadir nuevos grupos familiares, beneficiarios y mascotas';
      let dataSession = JSON.parse(session);
      // Agregar el whoShedule al objeto appointment
      this.appointment.whoScheduled = dataSession._idData;
      // // console.log('agregando el whoShedule desde validacion logeado', this.appointment.whoScheduled);
      // Obtener info del user logeado para usarle en caso de no tener beneficiarios
      //TODO: Descomentar si se llega a necesitar
      this.getInfoUser(dataSession._idData);
      // Validacion para traer los grupos familiares dependiendo el servicio o especialidad de mascotas o humanos
      if (this.specialityOfHumans === true && this.specialityOfPets === false) {
        // Se otienen los grupos familiares y sus beneficiarios si llega  a tener
        this.getGroupsFamilyAndBeneficiries();
      }

      if (this.specialityOfHumans === false && this.specialityOfPets === true) {
        // Se otienen los grupos familiares y sus mascotas si llega a tener
        this.getPetsgetPetsGroupsFamily();
      }

      // Se condiciona los steps que se van a mostrar cuando se esta logeado, en caso de no logeado inicialmente son los 5 primeros hasta que se verifique.
      this.initialStepsSessionLogged = [1, 2, 3, 4];
      this.cdr.detectChanges();
      // // console.log('logeado?', this.loginMoment)
    }
    else {
      this.loginMoment = false;
      this.textPaciente = 'Completa el formulario con los datos del paciente que requiere la cita'
      this.initialStepsSessionNotLogged = [1, 2, 3];
      this.cdr.detectChanges();
      // // console.log('logeado?', this.loginMoment);
    }
  }

  // METODO PARA VALIDAR EL TIPO DE SERVICIO
  validtedSubService() {
    this.specialityOfHumans = false;
    this.specialityOfPets = false;



    // OBTENER LOS PRECIOS DEL SERVICIO
    let priceService = this.subServiceDetails.price;
    this.infoAppointment.price = priceService;
    // // console.log('precio servicio', this.infoAppointment.price);

    let discontedPriceService = this.subServiceDetails.discountedPrice;
    this.infoAppointment.discountedPrice = discontedPriceService;


    // //console.log('precio con descuento ticktet', this.discountedPriceTicket);

    //OBTENER EL NOMBRE DEL SUBSERVICIO
    let nameService = this.subServiceDetails.subserviceName;
    this.nameSubservice = nameService;
    this.infoAppointment.subservice = nameService;
    // //console.log('name service', this.nameSubservice);

    // SERVICIO PETS
    if (this.subServiceDetails.speciality.specialityId === '640405ef0c0fa4df0711692f') {
      this.specialityOfPets = true;
      this.specialityOfHumans = false;
      // //console.log('servicio de mascotas', this.subServiceDetails.speciality.specialityId);
      return
    }
    // SERVICIO PERSONAS
    this.specialityOfPets = false;
    this.specialityOfHumans = true;
    // //console.log('servicio de personas', this.subServiceDetails.speciality.specialityId);
  }

  // METODOS PARA EL ALBUM
  positionEmitter(position: number) {
    const modalContent = ImagesViewerComponent;
    const options: ModalOptions = {
      modalClass: 'modal-dialog modal-lg',
      modalClassContent: 'modal-content-adjust',
      modalClassBody: 'modal-body-adjust',
      header: false,
      footer: false,
      dataKeyboard: false,
      static: 'static'
    }
    this.modalService.openModal(modalContent, options, { album: this.albumSelected, position });
  }

  regresarEmmiter(regresar: boolean) {
    if (regresar) {
      this.albumSelected = undefined;
    }
  }

  albumEmitter(album: Album) {
    this.albumSelected = album;
  }

  // PARA EL SIGUIMIENTO DE DE GOOGLE ADS

  // METODO PARA AGREGAR EL NUEVO PATH A LA URL
  addNewPath(step: string) {
    // Obtener la URL actual completa
    let url = this.router.url;

    // Separar el path y los parámetros de la url
    let path = url.split('?')[0];
    let params = url.split('?')[1];

    // // console.log('url', url, 'path', path, 'params', params);


    // Crear la nueva URL con el nuevo parámetro
    let newUrl = `${path}${params ? '?' + params : ''}/${step}`;
    // // console.log('New URL:', newUrl);

    // let pathNewUrl = newUrl.split('?')[0];
    // let paramsNewUrl = newUrl.split('?')[1];
    // let paramStep = paramsNewUrl.split('/');


    // // // console.log('url', newUrl, 'path', pathNewUrl, 'params', paramsNewUrl);
    // // console.log(paramStep);


    // Usar location para modificar la URL sin navegar y que google ads sepa donde estamos :3
    this.location.replaceState(newUrl);
  }

  //  METODO PARA ELIMINAR PARAMETROS A LA URL
  removeExtraPath() {
    // Obtener la URL actual completa
    let url = this.router.url;

    // Separar el path y los parámetros de la url
    let path = url.split('?')[0];
    let params = url.split('?')[1];

    // Eliminar cualquier segmento adicional del path
    let paramParam = params.split('%')[0];
    let stepParam = params.split('%')[1];


    // Crear la URL inicial sin segmentos adicionales
    let newUrl = `${path}${paramParam ? '?' + paramParam : ''}`;

    // Usar location para modificar la URL sin navegar
    this.location.replaceState(newUrl);
  }

  // =================== STEP MOTIVO CITA ======================== //

  // FORMULARIO DE MOTIVO DE CITA
  iniciarFormularioMotivoCita() {
    this.reasonAppointmentForm = new FormGroup({
      appointMotive: new FormControl(''),
      branch: new FormControl<string | null>(null, Validators.required),
      doctor: new FormControl<string | null>(null, Validators.required)
    });

    //  ESCUCHAR EL SELECT DE SUCURSAL
    this.reasonAppointmentForm.get('branch')?.valueChanges.subscribe(branchId => {
      this.onBranchChange(branchId);
    });
  }

  // PASAR LAS OPCIONES DE SUCURSALES AL SELECT
  transformBranchesToSelectOptions(branches: any[]): any[] {
    return branches.map(branch => ({
      value: branch.branch._id,
      direccion: branch.branch.addressSettings.address,
    }));
  }

  // PASAR LAS OPCIONES DE DOCTORES AL SELECT
  transformDoctorsToSelectOptions(doctors: any[]): any[] {
    return doctors.map(doctor => ({
      value: doctor._id,
      name: `${doctor.nombres} ${doctor.apellidos}`,
    }));
  }

  // BUSCAR UNA SUCURSAL POR EL ID ADEMAS SE LE PASA EL ARRAY DONDE SE BUSCA
  findBranchById(id: string, branches: any[]): any {
    return branches.find(branch => branch.branch._id === id) || null;
  }

  // BUSCAR LOS DOCTORES POR EL ID Y TAMBIEN SE LE PASA EL ARRAY DONDE BUSQUE
  findDoctorById(id: string, doctors: any[]): any {
    return doctors.find(doctor => doctor._id === id) || null;
  }


  updateNextButtonState(): void {
    if (!this.reasonAppointmentForm) {
      return;
    }

    this.disableNext = !this.reasonAppointmentForm.valid;

    if (this.reasonAppointmentForm.valid) {
      const reason = this.reasonAppointmentForm.get('appointMotive')?.value.trim() || 'Sin motivo';
      const branchId = this.reasonAppointmentForm.get('branch')?.value;
      const doctorId = this.reasonAppointmentForm.get('doctor')?.value;

      if (this.loginMoment) {
        //
        this.inputData.branchId = branchId;
        this.inputData.doctorId = doctorId;
        this.appointment.appointmentReason = reason;
        this.appointment.branchId = branchId;
        this.appointment.doctorId = doctorId;

        // Buscar la sucursal seleccionada
        const searchBranch = this.findBranchById(branchId, this.branchsTotal);
        const searchDoctor = this.findDoctorById(doctorId, searchBranch?.doctors ?? []);

        if (searchBranch) {
          this.appointmentResumen.sucursal = searchBranch.branch.name;
          this.appointmentResumen.direccion = searchBranch.branch.addressSettings.address;
          this.appointmentResumen.telefonos = searchBranch.branch.cellPhones[0]?.phone;
          this.appointmentResumen.barrio = searchBranch.branch.addressSettings.neighbor;

          this.infoAppointment.entity = searchBranch.branch.name;
          this.infoAppointment.address = searchBranch.branch.addressSettings.address;
          this.infoAppointment.cellphone = searchBranch.branch.cellPhones[0]?.phone;
        }

        if (searchDoctor) {
          this.appointmentResumen.medico.nombres = searchDoctor.nombres;
          this.infoAppointment.doctor = searchDoctor.nombres;
        }
      } else {

        this.inputData.branchId = branchId;
        this.inputData.doctorId = doctorId;
        this.appointmentTemp.appointmentReason = reason;
        this.appointmentTemp.branchId = branchId;
        this.appointmentTemp.doctorId = doctorId;
      }
    }
  }

  // Enviar y validar el motivo de cita para agregarlo a la solicitud de cita.
  confirmValueAndNext() {
    if (this.reasonAppointmentForm.value) {
      // Agregar el valor del textArea a la propiedad appointmentReason
      const appointmentReason = this.reasonAppointmentForm.value.appointMotive;
      // //console.log('motivo de cita:', appointmentReason);
      // Asignar appointmentReason a la propiedad appointmentReason de la interfaz Appointment
      this.appointment.appointmentReason = appointmentReason;

    }
  }

  // Si se cambia al Step Motivo de cita , mantener el value de appointmentsReasonPresent guardado en appointments
  verifyValueDataAppointmentReason() {
    //Recoge el value del objeto appoimentReason y lo guarda en la constante
    const appoinmentReasonPresent = this.appointment.appointmentReason;
    // //console.log('mantiene motivo de cita Presente:', appoinmentReasonPresent);

    if (appoinmentReasonPresent) {
      if (appoinmentReasonPresent.length >= 0) {
        // Mantiene el value de en el textarea sin importar si cambio de step
        const appoinmentViewReason = this.reasonAppointmentForm.setValue({ appointMotive: appoinmentReasonPresent });
        // //console.log('cita', appoinmentViewReason);
        return appoinmentViewReason
      } else {
        // this.disableNext = true;
      }
    }
  }


  // CUANDO SE SELECCIONA UNA SUCURSAL
  onBranchChange(branchId: string): void {
    // Busca la sucursal
    const selectedBranch = this.findBranchById(branchId, this.branchsTotal);

    // Si se encuentra la sucursal, transformamos los doctores a las opciones del select
    if (selectedBranch) {
      this.doctorsSelect = this.transformDoctorsToSelectOptions(selectedBranch.doctors);
    } else {
      this.doctorsSelect = []; //  limpiamos el select de doctores
    }

    // Limpiar el doctor seleccionado si cambia la sucursal :3
    this.reasonAppointmentForm.get('doctor')?.setValue(null);
  }


  // Método para cargar los doctores según la sucursal seleccionada
  updateDoctorsSelect(branchId: string): void {
    const selectedBranch = this.findBranchById(branchId, this.branchsTotal);
    if (selectedBranch) {
      // Transformar doctores a opciones de select
      this.doctorsSelect = this.transformDoctorsToSelectOptions(selectedBranch.doctors);
      // console.log('Doctores para la sucursal seleccionada:', this.doctorsSelect);

      // Limpiar  cuando cambia la sucursal
      this.reasonAppointmentForm.get('doctor')?.setValue(null);
    }
  }

  // =================== STEP  HORARIO ======================== //

  onPayloadData(payload: Hour) {
    this.payload = payload;
    // // console.log('Datos de Calendario:', this.payload);
    // sacar el available

    if (payload) {
      this.disableNext = false;
      const available = payload.available;
      this.availableAppoiment = available;
      // //console.log('available', this.availableAppoiment);
      if (this.loginMoment === true) {
        // Sacamos la hora seleccionada del calendario y la agregamos a appointments y a appointmentResumen
        const horaFormateada = this.formatHourToAMPM(payload.hour);
        this.appointment.hour = payload.hour;
        this.appointmentResumen.hora = horaFormateada;
        this.infoAppointment.hour = payload.hour;
        // Sacamos la fecha seleccionada del calendario  y la agregamos a appointments y a appointmentResumen
        this.appointment.appointmentDate = payload.date;
        this.appointmentResumen.fecha = payload.date;
        this.infoAppointment.fechaCita = payload.date;
        // Sacamos el shifID generado en el calendario y la agregamos a appointments
        this.appointment.shiftId = payload.shiftId;
        // Sacamos la office generada en el calendario y la agregamos a appointments y a appointmentResumen
        this.appointment.office = payload.office;
        this.appointmentResumen.consultorio = payload.office;
        // appointment y appointmentResumen despues de agregar los datos del calendario
        // //console.log('cita', this.appointment);
        // // console.log('cita resumen', this.appointmentResumen);
        //Genera el id unico de la cita
        this.generateidAppointment();
        this.infoAppointment.idCita = this.appointment.receiptNumber;
        this.selectCalendar = true;
      }
      if (this.loginMoment === false) {
        // Sacamos la hora seleccionada del calendario y la agregamos a appointments y a appointmentResumen
        // const horaFormateada = this.formatHourToAMPM(payload.hour);
        this.appointmentTemp.hour = payload.hour;
        // this.appointmentResumen.hora = horaFormateada;
        // Sacamos la fecha seleccionada del calendario  y la agregamos a appointmentTemp
        this.appointmentTemp.appointmentDate = payload.date;
        // Sacamos el shifID generado en el calendario y la agregamos a appointmentTemp
        this.appointmentTemp.shiftId = payload.shiftId;
        // Sacamos la office generada en el calendario y la agregamos a appointmentTemp
        this.appointmentTemp.office = payload.office;
        // Validar el servicio y pasar el patienType a  appointmentTemp
        // Agregar el type members | pets a patienType
        if (this.specialityOfHumans === true) {
          let patientType = 'members';
          this.appointmentTemp.patientType = patientType;
        }

        if (this.specialityOfPets === true) {
          let patientType = 'pets';
          this.appointmentTemp.patientType = patientType;
        }

        this.selectCalendar = true;
        // // console.log('cita Temp', this.appointmentTemp);

      }
    }
  }

  onErrorNoData(error: any) {
    // console.error('Error sin datos:', error);
  }



  // Obtener la Hora en formato 12 horas
  formatHourToAMPM(hourString: string): string {
    const [hour, minutes] = hourString.split(':').map(Number); // Convierte a números
    const isPM = hour >= 12;
    const formattedHour = hour % 12 || 12; // Convierte a formato de 12 horas, usando 12 si es mediodía o medianoche
    const amPmSuffix = isPM ? 'PM' : 'AM';

    return `${formattedHour}:${minutes
      .toString()
      .padStart(2, '0')} ${amPmSuffix}`;
  }

  // Generar id de cita y agregar el value de patientType del objeto appointments
  generateidAppointment(): string {

    // Agregar el type members | pets a patienType
    if (this.specialityOfHumans === true) {
      let patientType = 'members';
      this.appointment.patientType = patientType;
    }

    if (this.specialityOfPets === true) {
      let patientType = 'pets';
      this.appointment.patientType = patientType;
    }



    const array = 'abcdefghijkmnpqrtuvwxyzABCDEFGHJKMNPQRTUVWXYZ012346789';
    let caracter = '';
    for (let i = 0; i < 9; i++) {
      caracter += array.charAt(Math.floor(Math.random() * array.length));
    }
    const idCita = caracter + new Date().getMilliseconds();
    // //console.log('id cita generado', idCita);
    // Agregar el id de la cita al objeto appointments
    return (this.appointment.receiptNumber = idCita);


  }

  // =================== STEP PACIENTE O CITA TEMP ================ //




  iniciarFormularioTemp() {
    this.formTemp = new FormGroup({
      nombresTemp: new FormControl('', [Validators.required, Validators.minLength(2), Validators.maxLength(30)]),
      apellidosTemp: new FormControl('', [Validators.required, Validators.minLength(2), Validators.maxLength(30)]),
      telefonoTemp: new FormControl('', [Validators.required, Validators.maxLength(10), Validators.pattern(/^3\d{9,9}/)]),
      tipoDocumentoTemp: new FormControl('', [Validators.required]),
      code: new FormControl(null),
      // terms: new FormControl(false)
    })



    this.listenValueFormTemp();
  }

  iniciarFormularioValidarUser() {
    this.formValidateUser = new FormGroup({
      identificacionUser: new FormControl('', Validators.required)
    });

  }

  iniciarFormularioOptionsCode() {
    this.formOptionsCode = new FormGroup ({
      cellUser: new FormControl ('', [Validators.required, Validators.maxLength(10), Validators.pattern(/^3\d{9,9}/)]),
      codeUser: new FormControl (null, Validators.required)
    })

    this.listenValueFormOptions();
  }

  // Metodo para validar el Paciente
  validateUserTemp() {
    const { identificacionUser: { document, type } } = this.formValidateUser.value
    try {
      this.infoService.getValidDocument(document).subscribe(resp => {

        const { body, message, ok } = resp;
        // console.log('respuesta de la peticion', body);
        // console.log('cita temp', this.appointmentTemp);


        // Si el user no existe
        if (!body.existe && this.init) {
          // Mostrar formulario para que complete los datos
          this.init = !this.init;
          this.verifyStatus = !this.verifyStatus;
          // Seteamos el valor en el formulario de temporales
          this.formTemp.patchValue({
            tipoDocumentoTemp: {
              document: document,
              type: type
            }
          });

          // Si existe pasamos el valor al appointmentTemp
          this.appointmentTemp.numeroDocumento = document;
          this.appointmentTemp.tipoDocumento = type;

          // Bloquear el input
          this.formTemp.controls['tipoDocumentoTemp'].disable();
          // console.log('temporal no existe', this.appointmentTemp);
          
        }
        // Si el user existe
        if (body.existe && this.init) {
          this.appointmentTemp.nombres = body.user.nombres;
          this.appointmentTemp.apellidos = body.user.apellidos;
          // this.appointmentTemp.telefono = body.user.cellPhones[0].phone;
          this.appointmentTemp.tipoDocumento = body.user.tipoDocumento;
          this.appointmentTemp.numeroDocumento = body.user.numeroDocumento;
          this.num = body.user.cellPhones[0].phone;
          // this.prefix = body.user.cellPhones[0].prefix;
          // Mostrar  envio del codigo
          this.verifyStatus = !this.verifyStatus;
          this.codeSend = !this.codeSend;
          // Inicializar el form
          this.iniciarFormularioOptionsCode();
          // Setear el numero de telefono
          this.formOptionsCode.patchValue({
           cellUser: this.num
          })
          // console.log('temporal  existe', this.appointmentTemp);
        }

      })
    }
    catch (error) {
      //TODO: Maneja errores
      // this.swalService.lauchSwal()
    }

  }

  // Metodo para el flip de verificacion
  flipVerify() {
    this.init = true;
    this.verifyStatus = false;
    this.codeSend = false;
    this.selectCode = null;
    this.formTemp.reset();
  }

  // Agregar el type de envio de codigo de verificacion
  addSendType(type: string) {
    // Se pasa el tipo de envio de codigo
    this.appointmentTemp.sendType = type;
    this.selectCode = type;
    // Si el user existe pasar el id del patient a this.appointmentTemp.whoScheduled
    // this.appointmentTemp.whoScheduled = '';
    // console.log('cita temporal despues del sendCode', this.appointmentTemp);
    this.disableNext = false;
    // Enviar la cita temporal
    // this.userService.postAppointmentTemp(this.appointmentTemp).then(resp => {
    // //   console.log('respuesta de la temp', resp);
    // })
  }


  // Metodo para escuchar el valor del input verificar
  listenValueVerify() {
    this.formValidateUser.get('identificacionUser')?.valueChanges.subscribe(value => {

      let { document } = value;

      // Verifica si se ha escrito algo
      if (document && document.trim()) {
        // Habilita el boton
        this.disableVerify = false;
      } else {
        // Desabilitar el boton
        this.disableVerify = true;
      }
    })

  }


  // Metodo para escuchar el valor del form
  listenValueFormTemp() {
    this.formTemp.valueChanges.subscribe(value => {
      const { apellidosTemp, nombresTemp, telefonoTemp, code } = value;

      // console.log('valor del formtemp', value);

      // Verificar que los valores no sean null o undefined antes de aplicar trim
      const nombresTrimmed = nombresTemp ? nombresTemp.trim() : '';
      const apellidosTrimmed = apellidosTemp ? apellidosTemp.trim() : '';
      const telefonoTrimmed = telefonoTemp ? telefonoTemp.trim() : '';

      // Si tiene todos los campos llenos, se muestra el input método de envío
      if (nombresTrimmed && apellidosTrimmed && telefonoTrimmed && this.formTemp.valid) {
        // // console.log('estan todos los campos con valor');
        // console.log('valor del telefono', telefonoTrimmed);

        // Pasar los valores del form al objeto appointmentTemp
        this.appointmentTemp.apellidos = apellidosTrimmed;
        this.appointmentTemp.nombres = nombresTrimmed;
        this.appointmentTemp.telefono = telefonoTrimmed;

        // console.log('cita', this.appointmentTemp);

        // Mostrar el select de opciones de código
        this.formCompleted = true;
        // Si la pantalla es desktop entonces se hace el scroll al select si no, no
        if (this.componentWidth > 1200) {
          setTimeout(() => {
            this.scrollService.scrollToElement('idInputCode');
          }, 100);
        }


        if (this.formCompleted === true) {
          if (code === 'wsp' || code === 'sms') {
            // Pasar el valor al objeto appointmentTemp
            this.appointmentTemp.sendType = code;
            // Habilitar el botón de enviar
            this.disableNext = false;
          }
        }

      } else {
        this.formCompleted = false;
        this.disableNext = true;
      }
    });
  }

  // Metodo para escuchar el valor de las opciones
  listenValueFormOptions(){
    this.formOptionsCode.valueChanges.subscribe(value => {
      // console.log('valor del form options', value);

      let {cellUser, codeUser} = value;

      if(this.formOptionsCode.valid){
        this.appointmentTemp.telefono = cellUser;
        this.appointmentTemp.sendType = codeUser;
        this.disableNext = false;
        // console.log('valido');
        // console.log('cita temporal', this.appointmentTemp);
      }
      else {
        // console.log('invalido');
        this.disableNext = true; 
      }

    })
  }


  // Abrir modal de verificacion de code
  openCodeVerify(cita: AppointmentTemp) {

    const modalContent = VerifyCodeComponent;
    const options: ModalOptions = {
      modalClass: ' modal-dialog modal_lg',
      modalClassContent: ' modal-content_adjust',
      modalClassBody: 'modal-body',
      tittle: 'Código de verificación',
      header: true,
      footer: false,
      dataKeyboard: false,
      static: 'static'
    }
    this.modalService.openModal(modalContent, options, cita);
  }

  

  // ========= Metodos paciente ============ //

  getIdPaciente(id: string, names?: string, surname?: string, phone?: string, gender?: string, document?: string) {
    this.selectPacienteID = id;
    this.gender = gender || 'No registra';

    if (this.isHumanSpeciality() && this.beneficiaryToFamilyGroupMap.has(id)) {
      this.updateAppointmentDetails(id, this.beneficiaryToFamilyGroupMap.get(id));
      this.updateAppointmentResumen(names, surname, phone, document);
      this.disableNext = false;
      // // console.log('caso beneficiario está en el map');
      // // console.log('cita',this.appointment);
      // // console.log('cita resumen', this.appointmentResumen);

      return;
    }

    if (this.isPetSpeciality() && this.petToFamilyGroupMap?.has(id)) {
      this.updateAppointmentDetails(id, this.petToFamilyGroupMap.get(id));
      this.updateAppointmentResumen(names, surname, phone, document);
      this.disableNext = false;
      // // console.log('caso pet está en el map');
      // // console.log('cita',this.appointment);
      // // console.log('cita resumen', this.appointmentResumen);
      return;
    }


    this.updateAppointmentResumen(names, surname, phone, document);
    this.appointment.patient = id;
    // // console.log('cita',this.appointment);

    if (this.appointment && this.appointment.appointmentDetails && this.appointment.patient == this.appointment.appointmentDetails.attendant) {
      this.updateAppointmentResumen(names, surname, phone, document);
      unset(this.appointment, 'appointmentDetails');
      // console.log('entro a la condicion wuuuu');
      // console.log('despues de borrar el appointmentDetails', this.appointment, this.appointmentResumen);


    }
    this.disableNext = false;
  }

  private isHumanSpeciality(): boolean {
    return this.specialityOfHumans && !this.specialityOfPets;
  }

  private isPetSpeciality(): boolean {
    return this.specialityOfPets && !this.specialityOfHumans;
  }


  private updateAppointmentDetails(patientId: string, familyGroupId: string | undefined) {
    if (!this.appointment.appointmentDetails) {
      this.appointment.appointmentDetails = {};
    }

    this.appointment.patient = patientId;
    this.appointment.appointmentDetails.attendant = this.dataUserLogeado.userId;
    this.appointment.appointmentDetails.familyGroup = familyGroupId;

  }

  private updateAppointmentResumen(names?: string, surname?: string, phone?: string, document?: string) {
    this.appointmentResumen.paciente.nombres = names || '';
    this.appointmentResumen.paciente.apellidos = surname || '';
    this.infoPatient.nombres = names || '';
    this.infoPatient.apellidos = surname || '';
    this.infoPatient.numeroDocumento = document || '';
    this.appointmentResumen.telefonos = phone || 'No Registra';


  }


  getIdMascota(id: string, names?: string, surname?: string, species?: string, gender?: string, breed?: string, birthDate?: string, ownerName?: string, ownerSurname?: string, idOwner?: string, phone?: string) {
    const idMascota = id;
    const namesMascota = names;
    const surnameMascota = surname;
    const specieMascota = species;
    const phoneOwner = phone;
    const genderMascota = gender;
    const breedMascota = breed;
    const birthDateMascota = birthDate;
    const ownerNameMascota = ownerName;
    const ownerSurnameMascota = ownerSurname;
    const idOwnerMascota = idOwner;

    // //console.log('nombres completos de la mascota', namesMascota, surnameMascota);
    // //console.log(' nombres completos del propietario',ownerNameMascota, ownerSurnameMascota )
    // Activar el modo seleccionado
    this.selectPacienteID = id;
    // //console.log('ID Patient:', idMascota, 'Seleccionado', this.selectPacienteID, 'genero', genderMascota);
    // Obtener el genero de la mascota y pasarlo a la variable para pasarlo al ticket o recibo
    this.gender = genderMascota;
    if (this.gender) this.gender = this.gender;
    if (!this.gender || this.gender === null || this.gender === undefined) {
      this.gender = ' No registra';
    }
    // Obtener el id de la mascota seleccionado y pasar a la propiedad patient del objeto appointment
    // Verificar si el id está incluido en el Map
    if (this.petToFamilyGroupMap.has(id) && this.specialityOfPets === true) {
      // Asegurarse de que appointmentDetails esté inicializado
      if (!this.appointment.appointmentDetails) {
        this.appointment.appointmentDetails = {}; // Inicializar como objeto vacío
      }

      // Si el id está presente, obtener el valor asociado (id del grupo familiar)
      const familyGroupIdPet = this.petToFamilyGroupMap.get(id);

      // Pasar el id del pet y el id del grupo familiar a las constantes
      const petId = id; // Este es el id del pet (paciente)
      const familyGroupPet = familyGroupIdPet; // Este es el id del grupo familiar asociado
      this.appointment.patient = petId;

      this.appointment.appointmentDetails.attendant = this.dataUserLogeado.userId;
      this.appointment.appointmentDetails.familyGroup = familyGroupPet;
      // //console.log(`pet ID: ${petId}, grupo familiar id: ${familyGroupPet}`);
    } else {
      // //console.log("El ID proporcionado del pet no está asociado a un grupo familiar.");
    }

    this.appointment.patient = idMascota;
    // //console.log('cita', this.appointment);
    // Pasar los nombres del paciente seleccionado al appointmentResumen
    this.appointmentResumen.paciente.nombres = namesMascota;
    this.appointmentResumen.paciente.apellidos = surnameMascota;
    this.infoPet.petName = namesMascota;
    this.infoPatient.nombres = ownerNameMascota;
    this.infoPatient.apellidos = ownerSurnameMascota;
    this.infoPatient.numeroDocumento = this.dataUserLogeado.documentNumber;
    // Pasar la especie de la mascota seleccionada al appointmentResumen
    this.appointmentResumen.especie = specieMascota;
    // Pasar la raza de la mascota seleccionada al appointmentResumen
    this.appointmentResumen.raza = breedMascota;
    // Pasar la fecha de nacimiento de la mascota al appointmentResumen
    this.appointmentResumen.fechaNacimiento = birthDateMascota;
    // Pasar el propietario de la mascota al appointmentResumen
    this.appointmentResumen.propietario.nombre = ownerNameMascota;
    this.appointmentResumen.propietario.apellidos = ownerSurnameMascota;
    // Pasar la identificacion del propietario al appointmentResumen
    this.appointmentResumen.cedulaPropietario = idOwnerMascota;
    // Pasar el telefono del paciente seleccionado al apppointmentResumen
    this.appointmentResumen.telefonos = phoneOwner;
    if (this.appointmentResumen.telefonos)
      this.appointmentResumen.telefonos = this.appointmentResumen.telefonos;
    if (
      !this.appointmentResumen.telefonos ||
      this.appointmentResumen.telefonos === null ||
      this.appointmentResumen.telefonos === undefined
    )
      this.appointmentResumen.telefonos = 'No Registra';

    //activar el siguiente
    this.disableNext = false;
    // // console.log('cita resumen de mascota', this.appointmentResumen);
    // // console.log('cita mascota', this.appointment);

  }


  // Metodo para el flip de Paciente
  viewContent() {
    this.viewContentPage = !this.viewContentPage;
    this.cdr.detectChanges();
    // //console.log('valor del viewContentPage', this.viewContentPage);
  }

  // Metodo flip para mostrar el grupo familiar seleccionado
  selectFamilyGroup(group: any): void {

    // // console.log('grupo seleccionado', group);

    const userId = this.dataFamilyGroupsAndBeneficiries?.accountHolderDetails?.userId;

    // Filtrar los beneficiarios, eliminando al user logeado
    if (this.specialityOfHumans && !this.specialityOfPets) {
      this.selectedFamilyGroup = {
        ...group,
        beneficiaries: group.beneficiaries.filter((beneficirie: any) => beneficirie.beneficiaryId !== userId)
      };

      this.viewContent();
    }

    if (!this.specialityOfHumans && this.specialityOfPets) {
      this.selectedFamilyGroup = {
        ...group,
        pets: group.pets.filter((pet: any) => pet !== userId)
      };

      this.viewContent();
    }
    // // console.log('grupo seleccionado', this.selectedFamilyGroup);

  }


  // ========== Metodos Grupos Familiar ============ //
  // Metodo para obtener los  grupos familiares y beneficiarios del user logeado.
  async getGroupsFamilyAndBeneficiries(): Promise<void> {
    try {
      const dataFamilyGroupsAndBeneficiries = this.userService.getGroupsFamilyAndBeneficiries();

      // Esperar a que se resuelva la subscripción antes de continuar
      return new Promise<void>((resolve, reject) => {
        dataFamilyGroupsAndBeneficiries.subscribe({
          next: (infoData) => {
            this.dataFamilyGroupsAndBeneficiries = infoData;
            // // console.log('Data de familias y beneficiarios:', this.dataFamilyGroupsAndBeneficiries);

            this.dataUserLogeado = this.dataFamilyGroupsAndBeneficiries.accountHolderDetails;
            // // console.log('Data del usuario logueado:', this.dataUserLogeado);

            this.dataFamilyGroups = this.dataFamilyGroupsAndBeneficiries.familyGroups.map((group: any) => ({
              ...group,
              flipped: false
            }));
            // // console.log('Data de grupos familiares:', this.dataFamilyGroups);


            if (this.dataFamilyGroups.length === 0 || this.dataFamilyGroups.length <= 0) {
              this.appointment.patient = this.dataUserLogeado.userId;
              this.NotFoundGroupsFamily = true;
            }
            if (this.dataFamilyGroups.length >= 1) {
              this.NotFoundGroupsFamily = false;
            }

            // Validar si el usuario es líder de más de un grupo familiar
            this.validateUserAsLeaderAndProceed();
            // Obtener todos los beneficiarios de los grupos familiares
            this.extractBeneficiaryIdsAndFamilyGroupIds();
            this.cdr.detectChanges();
            resolve();
          },
          error: (err) => {
            // console.error('Error al obtener la data de familias y beneficiarios', err);
            reject(err);
          },
          complete: () => {
            // // console.log('Toda la data de familias y beneficiarios ha cargado :)');
          },
        });
      });

    } catch (error) {
      // console.error('Error en getGroupsFamilyAndBeneficiries:', error);
      throw error;  // Propagar el error para manejarlo más arriba si es necesario
    }
  }


  // Función para verificar si el usuario es líder de algún grupo familiar
  isUserFamilyLeader(): boolean {
    let userId: any;
    let familyGroups: any;

    if (this.dataFamilyGroupsAndBeneficiries) {
      userId = this.dataFamilyGroupsAndBeneficiries.accountHolderDetails.userId;
      familyGroups = this.dataFamilyGroupsAndBeneficiries.familyGroups;
    }
    if (this.dataFamilyGroupsAndPets) {
      userId = this.dataFamilyGroupsAndPets.accountHolderDetails.userId;
      familyGroups = this.dataFamilyGroupsAndPets.familyGroups;
    }

    // Busca si algún grupo familiar tiene al usuario como líder y retorna un boleano
    return familyGroups.some((familyGroup: FamilyGroup) => familyGroup.familyLeader === userId);
  }

  //  usar la función de verificacion del lider
  async validateUserAsLeaderAndProceed() {
    try {
      const isLeader = this.isUserFamilyLeader();
      if (isLeader) {
        this.userMoreLeader = true;
        // // console.log('El usuario es líder de un grupo familiar.');
      } else {
        this.userMoreLeader = false;
        // // console.log('El usuario no es líder de ningún grupo familiar');
      }
    } catch (error) {
      // console.error('Error al validar el líder del grupo familiar:', error);
    }
  }

  // Obtener array de todos los beneficiarios de los grupos familiares
  extractBeneficiaryIdsAndFamilyGroupIds() {

    let userId = this.dataFamilyGroupsAndBeneficiries?.accountHolderDetails?.userId;

    this.beneficiaryToFamilyGroupMap = new Map();

    interface Beneficiary {
      beneficiaryId: string;
    }

    interface Group {
      familyGroupId: string;
      beneficiaries: Beneficiary[];
    }

    this.dataFamilyGroups.forEach((group: Group) => {
      group.beneficiaries.forEach((beneficiary: Beneficiary) => {
        if (beneficiary.beneficiaryId !== userId) {
          // Aquí vinculamos cada beneficiario con su respectivo grupo familiar
          this.beneficiaryToFamilyGroupMap.set(beneficiary.beneficiaryId, group.familyGroupId);
        }
      });
    });
    // // console.log('Mapa de Beneficiario a Grupo Familiar:', this.beneficiaryToFamilyGroupMap);
  }

  // Obtener  los pets de los grupos familiares
  async getPetsgetPetsGroupsFamily(): Promise<void> {
    try {
      const dataPetsGroupsFamily = this.userService.getPetsGroupsFamily();

      return new Promise<void>((resolve, reject) => {
        dataPetsGroupsFamily.subscribe({
          next: (infoData) => {
            this.dataFamilyGroupsAndPets = infoData;
            // // console.log('data del infodata', this.dataFamilyGroupsAndPets);

            this.dataUserLogeado = this.dataFamilyGroupsAndPets.accountHolderDetails;
            // // console.log('user logeado en mascotas', this.dataUserLogeado);

            this.dataPetsGroup = this.dataFamilyGroupsAndPets.familyGroups.map((group: any) => ({
              ...group,
              flipped: false
            }));
            // // console.log('data de los grupos de pets', this.dataPetsGroup);

            if (this.dataPetsGroup.length === 0 || this.dataPetsGroup.length <= 0) {
              this.NotFoundGroupsFamily = true;
            }
            if (this.dataPetsGroup.length >= 1) {
              this.NotFoundGroupsFamily = false;
            }

            // Validar si el usuario es líder de más de un grupo familiar
            this.validateUserAsLeaderAndProceed();
            // Obtener todas las mascotas de los grupos familiares
            this.extractPetIdsAndFamilyGroupIds();
            this.cdr.detectChanges();
            resolve();
          },
          error: (err) => {
            // console.error('Error al obtener la data de los Pets', err);
            reject(err);
          },
          complete: () => {
            // // console.log('Toda la data de los pets ha cargado :)');
          }
        });
      });
    } catch (error) {
      // console.error('Error en getPetsGroupsFamily:', error);
      throw error;  // Propagar el error para manejarlo más arriba si es necesario
    }
  }


  // Obtener array de todos los pets de los grupos familiares
  extractPetIdsAndFamilyGroupIds() {
    this.petToFamilyGroupMap = new Map();

    interface Pet {
      _id: string;
    }

    interface Group {
      familyGroupId: string;
      pets: Pet[];
    }

    this.dataPetsGroup.forEach((group: Group) => {
      group.pets.forEach((pet: Pet) => {
        // Aquí vinculamos cada beneficiario con su respectivo grupo familiar
        this.petToFamilyGroupMap.set(pet._id, group.familyGroupId);
      });
    });
    // // console.log('Mapa de Pets a Grupo Familiar:', this.petToFamilyGroupMap);
  }

  // Abrir el modal para registar grupo familiar y añadir beneficiarios
  addFamilyGroupAndBeneficiary() {
    const modalContent = FamilyGroupAndBeneficiaryComponent; // Componente del modal para ver y generar el ticket
    const options: ModalOptions = {
      modalClass: 'modal-dialog modal_xl', // Clases CSS para el modal
      modalClassContent: 'modal-content_adjust',
      modalClassBody: 'modal-body',
      header: true, // Mostrar o no el encabezado del modal
      footer: false, // Mostrar o no el pie de página del modal
      dataKeyboard: false, // Permitir el cierre del modal al presionar la tecla Esc
      static: 'static', // Evitar que el modal se cierre al hacer clic fuera de él
    };
    this.modalService.openModal(modalContent, options); // Abrir el modal con el componente y opciones proporcionadas
  }

  // // Abrir el modal para registar grupo familiar y añadir mascotas
  addFamilyGroupAndPet() {
    const modalContent = FamilyGroupAndPetComponent; // Componente del modal para ver y generar el ticket
    const options: ModalOptions = {
      modalClass: 'modal-dialog modal_xl', // Clases CSS para el modal
      modalClassContent: 'modal-content_adjust',
      modalClassBody: 'modal-body',
      header: true, // Mostrar o no el encabezado del modal
      footer: false, // Mostrar o no el pie de página del modal
      dataKeyboard: false, // Permitir el cierre del modal al presionar la tecla Esc
      static: 'static', // Evitar que el modal se cierre al hacer clic fuera de él
    };
    this.modalService.openModal(modalContent, options); // Abrir el modal con el componente y opciones proporcionadas
  }

  // ======== METODOS PARA EL GENERAR EL TICKET Y ENVIARLO ========== //

  // Metodo para inicializar la data de que se pasara al canvas condicionando si es servicio de personas o mascotas
  prepareDataForImage(dataCita: any) {
    // PARA CITA DE USER
    if (this.specialityOfHumans === true && this.specialityOfPets === false) {

      this.appointmentTicket = dataCita;
      this.appointmentResumen = this.appointmentResumen;
      this.appointmentTicketResumen = this.appointmentResumen;

      this.getInfoUser(this.appointmentTicket.patient)
        .toPromise()
        .then((infoUser: any) => {
          this.infoUser = infoUser;
          if (!this.infoUser.cellPhones && !this.infoUser.cellPhones[0].whatsapp) {
            // // console.log('no tiene whatsapp para enviar el ticket');
            // return console.log(' No se envia el ticket porque no tiene wp');

          }
          // utilizar this.infoUser para dibujar en el canvas de user
          //TODO: capturar el componente y pasarle eso a drawImageOnCanvasUser
          this.captureTicket();
          // this.drawImageOnCanvasUser();
        });
    }

    // PARA CITA DE PET.
    if (this.specialityOfPets === true && this.specialityOfHumans === false) {
      this.appointmentTicket = dataCita;
      this.appointmentResumen = this.appointmentResumen;
      this.appointmentTicketResumen = this.appointmentResumen;
      //TODO: capturar el componente y pasarle eso a drawImageOnCanvasUser
      this.captureTicket();
      // this.drawImageOnCanvasPet();
    }
  }


  drawImageOnCanvasUser(img64: string): void {
    let img = new Image();
    img.src = img64;

    img.addEventListener('load', () => {
      const canvas = this.canvasEl.nativeElement;
      const context = canvas.getContext('2d');

      if (context) {
        this.context = context;
        canvas.width = img.width;
        canvas.height = img.height;

        this.context.drawImage(img, 0, 0);
        // this.renderTextOnCanvasUser();
        let img64 = this.canvasEl.nativeElement.toDataURL('image/jpeg', 0.5);

        // Validación mejorada
        const cellPhone = this.infoUser.cellPhones?.[0]?.phone;
        const whatsapp = this.infoUser.cellPhones?.[0]?.whatsapp;

        if (!cellPhone || !whatsapp) {
          // // console.log('No se envio el ticket porque no tiene numero');
          return;
        } else {
          const data: SendTicketAppointment = {
            caption: `Se ha reservado una cita para el servicio de ${this.nameSubservice} en ${this.appointmentTicketResumen.sucursal} con direccion ${this.appointmentTicketResumen.direccion} ${this.appointmentTicketResumen.barrio}. Para el dia ${this.appointmentTicketResumen.fecha} a las ${this.appointmentTicketResumen.hora}. Si deseas mas informacion comunicate con la empresa prestadora de salud al numero ${this.infoAppointment.cellphone}. Recuerda cumplir con las normas de bioseguridad y llegar 15 minutos antes de la cita.`,
            number: cellPhone,
            mediab64: img64,
          };

          this.userService.sendTicketAppointment(data);
          // // console.log('se envio el ticket porque tiene numero');
        }
      }


    });
  }


  //Metodo para configurar el canvas de mascota y su envio
  drawImageOnCanvasPet(img64: string) {
    let img = new Image();
    img.src = img64;

    img.addEventListener('load', () => {
      const canvas = this.canvasPets.nativeElement;
      const context = canvas.getContext('2d');

      if (context) {
        this.context = context;
        canvas.width = img.width;
        canvas.height = img.height;

        this.context.drawImage(img, 0, 0);
        // this.renderTextOnCanvasPets();
        let img64 = this.canvasPets.nativeElement.toDataURL('image/jpeg', 0.5);

        const data: SendTicketAppointment = {
          caption: `Se ha reservado una cita para el servicio de ${this.nameSubservice} en ${this.appointmentTicketResumen.sucursal} con direccion ${this.appointmentTicketResumen.direccion} ${this.appointmentTicketResumen.barrio}. Para el dia ${this.appointmentTicketResumen.fecha} a las ${this.appointmentTicketResumen.hora}. Si deseas mas informacion comunicate con la empresa prestadora de salud al numero ${this.phoneBranch}. Recuerda cumplir con las normas de de bioseguridad y llegar 15 minutos antes de la cita.`,
          number: this.appointmentTicketResumen.telefonos,
          mediab64: img64,
        };

        this.userService.sendTicketAppointment(data);
      }

    });
  }

  // Genera la imagen del ticket
  generateImage(htmlNode: HTMLElement): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      try {
        html2canvas(htmlNode, {
          scale: 2, // Incrementa la escala para mejorar la calidad
          // useCORS: true, // Permite cargar imágenes de recursos remotos con CORS
          // logging: true,
          allowTaint: false,
        })
          .then((canvas) => {
            const dataUrl = canvas.toDataURL('image/png', 1.0);
            resolve(dataUrl);
          })
          .catch((error) => {
            // console.error('Error generating image:', error);
            reject(error);
          });
      } catch (error) {
        // console.error('Unexpected error:', error);
        reject(error);
      }
    });
  }

  captureTicket(): void {
    try {
      let elem = document.querySelector('.ticket-container') as HTMLElement;
      if (elem) {
        this.generateImage(elem)
          .then((dataUrl) => {
            // // console.log('dataUrl', dataUrl);
            if (this.specialityOfHumans && !this.specialityOfPets) {
              this.drawImageOnCanvasUser(dataUrl);

            }
            if (!this.specialityOfHumans && this.specialityOfPets) {
              this.drawImageOnCanvasPet(dataUrl);
            }
          })
          .catch((error) => {
            // console.error('Error capturing ticket:', error);
          });
      } else {
        // console.error('Element with class .ticket-container not found');
      }
    } catch (error) {
      // console.error('Unexpected error during captureTicket:', error);
    }
  }

  // funcion final para aplicar el guardado y envio de la cita
  generateImgAppointment(dataCita: any) {
    this.prepareDataForImage(dataCita);
  }

  // Formatear la fecha de nacimiento sin librerias :)
  formatearFechaISO(fechaNacimiento: string) {
    // Extrae los componentes de la fecha
    const [fecha] = fechaNacimiento.split('T');
    const [año, mes, día] = fecha.split('-');

    // Construye la fecha en formato 'yyyy-mm-dd'
    const fechaFormateada = `${año}-${mes}-${día}`;

    return fechaFormateada;
  }

  // Metodo para limitar texto, si el texto paso la cantidad de caracteres de maxLength se corta la cadena de texto y se aumenta ...
  maxLengthCaracteres(texto: any, maxLength: any) {
    if (!texto) return;

    let textoImagen;
    if (texto?.length > maxLength)
      textoImagen = `${texto.substring(0, maxLength)} ...`;
    else textoImagen = texto;

    return textoImagen[0].toUpperCase() + textoImagen.substring(1);
  }

  // Metodo para obtener la edad
  calcularEdad(fechaNacimiento: string) {
    // Convertir la fecha de nacimiento en un objeto de fecha
    const fechaNac = new Date(fechaNacimiento);

    // Obtener la fecha actual
    const fechaActual = new Date();

    // Calcular la diferencia en años
    let edad = fechaActual.getFullYear() - fechaNac.getFullYear();

    // Verificar si ya ha pasado el cumpleaños este año
    const mes = fechaActual.getMonth() - fechaNac.getMonth();

    if (mes < 0 || (mes === 0 && fechaActual.getDate() < fechaNac.getDate())) {
      edad--;
    }

    // Convertir la edad a un string y retornarla
    return edad.toString();
  }

  // Metodo para darle formato al precio
  precioFormat(value: string) {
    value = String(value).replace(/\D/g, '');
    return value === '' ? value : Number(value).toLocaleString();
  }

  // ============================================ //

  // METODO DEL EVENT FINISH CUANDO SE SACA LA CITA
  sendAppointment() {
    const dataCita: Appointment = this.appointment;
    // // console.log('cita', dataCita);
    // Servicio que genera el ticket de la cita
    // this.generateImgAppointment(dataCita);

    this.userService.postAppointment(dataCita).then((response) => {
      // console.log('cita generada', response);
      // Servicio que genera el ticket de la cita
      this.generateImgAppointment(dataCita);
      // // Abrir SweetAlert  si se saco la cita medica

      setTimeout(() => {
        // this.swalService.lauchSwal(
        //   'Cita Agendada',
        //   'Se ha agendado correctamente',
        //   'success'
        // );
        this.swalService.showSuccess('','Cita agendada correctamente')
        //Redirigir  para mostrar la cita dependiendo si es para beneficiario o no
        //   // CASO 1: Para el user
        if (response.body.patient && !response.body.appointmentDetails && this.specialityOfHumans === true && this.specialityOfPets === false) {
          this.router.navigate(['/user/citas']);
        }
        //   // CASO 2: Para un beneficiario
        if (response.body.patient && response.body.appointmentDetails && this.specialityOfHumans === true && this.specialityOfPets === false) {
          this.router.navigate(['/user/appointment-beneficiary']);
        }
        //   // CASO 3: Para una mascota
        if (response.body.patient && response.body.appointmentDetails && this.specialityOfHumans === false && this.specialityOfPets === true) {
          this.router.navigate(['/user/appointment-pet'])
        }
      }, 500);
    }).catch((error) => {
      // console.error('Ocurrio un error, no se pudo solicitar cita', error);
    })



  }

  // METODO QUE ENVIA EL CODIGO Y ABRE EL MODAL
  sendCode() {
    const citaTemp: AppointmentTemp = this.appointmentTemp;
    // console.log('cita que se envia', this.appointmentTemp);

    this.userService.postAppointmentTemp(citaTemp).then((res) => {
      // console.log(' Se envio el codigo', res);
      // Abrir modal de codigo de verificacion
      this.openCodeVerify(citaTemp);

    })

  }


  ngOnDestroy() {
    this.subs.unsubscribe();
    this.componentWidthSubscription.unsubscribe();
  }
}
