import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
  TemplateRef,
  ElementRef,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  Appointment,
  AppointmentActiveSubservice,
  AppointmentDoctor,
  RescheduleAppointment,
  SendTicketAppointment,
} from '@interfaces/appointments';
import {
  Branch,
  CellPhone,
  Pet,
  Translation,
} from '@interfaces/information.interface';
import { Tab } from '@interfaces/shared-components.interface';
import { UserService } from 'src/app/roles/services/user.service';
import {
  ApplicationService,
  SocketsService,
  StatesNgrxService,
  SweetAlertService,
} from '@services';
import html2canvas from 'html2canvas';
import { ModalService } from 'src/app/reusable-modal/modal.service';
import { AppointmentsService } from 'src/app/services/appointments.service';

import Swal from 'sweetalert2';
import { log } from 'util';
import { formatInTimeZone } from 'date-fns-tz';

interface UserResp {
  birthDate: string;
  lastNames: string;
  names: string;
  avatar: string;
}

@Component({
  selector: 'app-appointments-user',
  templateUrl: './appointments-user.component.html',
  styleUrls: ['./appointments-user.component.scss'],
})
export class AppointmentsUserComponent implements OnInit {
  @Input() user?: UserResp;
  @Input() pets?: Pet[] = [];
  @Input() appointments: Appointment[] = [];
  @Input() appointmentsPets: Appointment[] = [];
  @Input() appointmentsPaginator: Appointment[] = [];
  @Input() inputSubServiceId?: string;
  @Input() inputViewTitle = true;
  @Input() appointmentType = '';
  @Input() branchSelected!: Branch;
  @Input() petSelected?: Pet;
  @Input() petCase?: Pet;
  @Input() documentNumber!: string;
  @Input() documentNumberAcudiente!: string;
  @Input() tempAppointment?: any;

  @Input() switchPerfil?: any;

  @Input() userFromAdvisor?: any;

  @Input() inputColumns = {
    pet: false,
    profesional: true,
    sucursales: false,
    direccion: false,
    fecha: true,
    servicio: true,
    opciones: true,
    estados: false,
  };

  @Output() rescheduling = new EventEmitter<boolean>();
  @Output() appointmentEmitter: EventEmitter<Appointment> =
    new EventEmitter<Appointment>();

  @ViewChild('userView') userView!: TemplateRef<any>;
  @ViewChild('petsView') petsView!: TemplateRef<any>;

  public currentView!: TemplateRef<any>;

  activeSubServiceSelected?: AppointmentActiveSubservice;

  infoPatient: any = {};
  infoAppointment: any = {};
  infoPet: any = {};
  ordenDescuento: boolean = true;

  citasForm!: FormGroup;
  dateErrors: Record<string, string> = {};
  activeSubService: AppointmentActiveSubservice[] = [];
  doctors: AppointmentDoctor[] = [];
  doctorSelected?: AppointmentDoctor;
  inputData = {
    subServiceId: '',
    doctorId: '',
    branchId: '',
  };

  citaText = '';
  citaTextTemp = '';
  isAnotherDoctor = false;

  userFechaNacimiento = '';
  userEdad = '';

  numItems = 5;

  endDate = new Date();

  appointmentsSelected?: RescheduleAppointment;

  endPointCalendar: string | null = null;

  tabs: Tab[] = [
    { tabName: 'Usuario', tabSwitch: 'userView', tabClass: 'active' },
    { tabName: 'Mascotas', tabSwitch: 'petsView', tabClass: '' },
  ];

  traduccionesPets: Translation[] = [
    { term: 'male', translate: 'Macho' },
    { term: 'female', translate: 'Hembra' },
  ];
  tableData: any;

  constructor(
    private socket: SocketsService,
    public applicationService: ApplicationService,
    private swalService: SweetAlertService,
    private statesNgrxService: StatesNgrxService,
    private userService: UserService,
    private appointmentsService: AppointmentsService,
    private modalService: ModalService
  ) { }

  ngOnInit(): void {
    this.appointmentsSelected = undefined!;

    if (this.user) {
      this.userFechaNacimiento = this.getFechaStringPretty(this.user.birthDate);
      this.userEdad = this.getYearsToCurrentDate(this.user.birthDate);
      setTimeout(() => {
        this.currentView = this.userView;
      }, 500);

    }
  }

  @ViewChild('canvasEl') canvasEl!: ElementRef<HTMLCanvasElement>;
  public context!: CanvasRenderingContext2D;

  /**
   * Evento de app-tabs que emite el nombre de la tab a la que se hace click.
   *
   * @param {string} tab - Evento con el Nombre de la tab a la que se va a cambiar currentView.
   */
  switch(tab: string) {
    this.changeView(tab);
  }


  /**
   * Cambia currentView por la tap que ingresa como parámetro.
   *
   * @param {string} tab - Nombre de la tab a la que se va a cambiar currentView.
   */
  changeView(tab: string) {
    switch (tab) {
      case 'userView':
        this.currentView = this.userView;
        break;
      case 'petsView':
        this.currentView = this.petsView;
        break;
    }
  }

  iniciarUserForm(appointment: any) {
    this.citaText = this.fechaHoraString(appointment);
    this.citaTextTemp = this.citaText;
    this.doctors =
      this.activeSubService.find(
        (service) =>
          service.subServiceId === appointment.subservice.subServiceId
      )?.doctors || [];

    this.doctors = this.doctorNames(this.doctors);
    this.infoAppointment.doctor =
      appointment.doctor.names + ' ' + appointment.doctor.lastNames;

    // Actualiza la información del subservicio seleccionado
    this.activeSubServiceSelected = this.activeSubService.find(
      (service) => service.subServiceId === appointment.subservice.subServiceId
    );

    this.infoAppointment.price = this.activeSubServiceSelected?.price;
    this.infoAppointment.discountedPrice =
      this.activeSubServiceSelected?.discountedPrice;

    this.infoAppointment.subservice =
      this.activeSubServiceSelected?.subServiceName;

    // Inicializa el FormGroup
    this.citasForm = new FormGroup({
      servicio: new FormControl<string | null>(
        { value: appointment.subservice.subServiceId, disabled: true },
        Validators.required
      ),
      medico: new FormControl<string | null>(
        appointment.doctor.doctorId,
        Validators.required
      ),
      motivo: new FormControl<string | null>(
        { value: appointment.appointmentReason, disabled: true },
        Validators.required
      ),
    });

    // Suscribe cambios en el campo 'servicio'
    this.citasForm.get('servicio')?.valueChanges.subscribe((subServiceId) => {
      this.inputData.subServiceId = subServiceId;

      // Encuentra los doctores basados en el nuevo subServiceId
      this.doctors =
        this.activeSubService.find(
          (service) => service.subServiceId === subServiceId
        )?.doctors || [];
      this.doctors = this.doctorNames(this.doctors);

      // Actualiza la información del subservicio seleccionado
      this.activeSubServiceSelected = this.activeSubService.find(
        (service) => service.subServiceId === subServiceId
      );

      if (this.activeSubServiceSelected) {
        this.infoAppointment.price = this.activeSubServiceSelected.price;
        this.infoAppointment.discountedPrice =
          this.activeSubServiceSelected.discountedPrice;
        this.infoAppointment.subservice =
          this.activeSubServiceSelected.subServiceName;
      } else {
        this.infoAppointment.price = 0;
        this.infoAppointment.discountedPrice = 0;
        this.infoAppointment.subservice = '';
      }

      // Realiza un desplazamiento suave hacia el campo 'medico'
      setTimeout(() => {
        this.hacerScroll('idInputMedico');
      }, 100);
    });

    this.citasForm.get('medico')?.valueChanges.subscribe((doctorId) => {
      this.inputData.doctorId = doctorId;
      this.doctorSelected = this.doctors.find(
        (doc) => doc.doctorId === doctorId
      );

      this.endPointCalendar = doctorId;

      if (this.doctorSelected) {
        this.infoAppointment.doctor =
          this.doctorSelected.names + ' ' + this.doctorSelected.lastNames;
      }

      if (this.appointmentsSelected) {
        this.appointmentsSelected.doctorId = doctorId;
      }
      appointment.doctor.doctorId === this.doctorSelected?.doctorId ? this.isAnotherDoctor = false : this.isAnotherDoctor = true;
    });

    this.citasForm.get('medico')?.valueChanges.subscribe((doctorId) => {
      this.inputData.doctorId = doctorId;
      this.doctorSelected = this.doctors.find(
        (doc) => doc.doctorId === doctorId
      );

      if (this.doctorSelected) {
        this.infoAppointment.doctor =
          this.doctorSelected.names + ' ' + this.doctorSelected.lastNames;
      }

      if (this.appointmentsSelected) {
        this.appointmentsSelected.doctorId = doctorId;
      }
    });

    this.citasForm.get('motivo')?.valueChanges.subscribe((motivo) => {
      if (this.appointmentsSelected) {
        this.appointmentsSelected.appointmentReason = motivo;
      }
    });
    this.getInfoPatient(appointment);
    this.getInfoAppointment(appointment);
  }

  getStrings(nString?: string): string {
    if (nString) {
      return nString;
    } else {
      return '';
    }
  }

  fechaHoraString(appointment: any): string {
    return `${this.getFechaStringPretty(
      appointment.appointmentDate
    )} a las ${this.applicationService.convertirHoraAMPM(appointment.hour)}`;
  }

  doctorNames(doctors: AppointmentDoctor[]): AppointmentDoctor[] {
    return doctors.map((doctor) => ({
      ...doctor,
      doctorNames: `${doctor.names} ${doctor.lastNames}`,
    }));
  }

  hacerScroll(id: string, margen?: number) {
    const elementoObjetivo = document.getElementById(id);
    if (elementoObjetivo) {
      const margenSuperior = margen ? margen : 100;
      const posicion = elementoObjetivo.getBoundingClientRect().top;
      const modalUser = document.getElementById('modal_user') as HTMLElement;
      const scrollTarget = modalUser?.scrollTop + posicion - margenSuperior;
      if (!modalUser) return;
      modalUser.scrollTo({
        top: scrollTarget,
        behavior: 'smooth',
      });
    }
  }

  getFechaStringPretty(fechaNacimiento: any): string {
    if (fechaNacimiento) {
      const fecha = new Date(fechaNacimiento);
      const fechaCorregida = new Date(fecha.setDate(fecha.getDate() + 1));
      return fechaCorregida.toLocaleDateString('es-co', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      });
    }
    return 'No registrado';
  }

  getYearsToCurrentDate(fecha: string | null): string {
    if (!fecha) {
      return '';
    } else {
      var hoy = new Date();
      var cumpleanos = new Date(fecha);
      var edad = hoy.getFullYear() - cumpleanos.getFullYear();
      var m = hoy.getMonth() - cumpleanos.getMonth();

      if (m < 0 || (m === 0 && hoy.getDate() < cumpleanos.getDate())) {
        edad--;
      }
      return `${edad} Años`;
    }
  }

  formatHour(value: string): string {
    if (!value) {
      return '';
    }

    const [hour, minutes] = value.split(':');
    let hourNumber = parseInt(hour);
    const meridian = hourNumber >= 12 ? 'PM' : 'AM';

    if (hourNumber > 12) {
      hourNumber -= 12;
    } else if (hourNumber === 0) {
      hourNumber = 12;
    }

    return `${hourNumber}:${minutes} ${meridian}`;
  }

  formatDatesForTable(date: any, formatDate: string): string {
    const timeZone = 'UTC';
    return formatInTimeZone(new Date(date), timeZone, formatDate);
  }

  pageChange(event: any) {
    setTimeout(() => {
      this.appointmentsPaginator = event;
      // this.tableDataMethod(this.appointmentsPaginator);
    }, 200);
  }


  async appointmentActivate(appointmen: any) {
    const citaHoy = this.applicationService.fechaEsHoy(
      appointmen.appointmentDate
    );

    if (!citaHoy) {
      const result = await Swal.fire({
        title: 'Advertencia',
        text: 'Solo se pueden confirmar las citas el mismo dia de la cita!',
        icon: 'warning',
        showCancelButton: false,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Confirmar!',
      });

      if (result.isConfirmed) {
        return;
      }
    } else {
      const result = await Swal.fire({
        title: 'Estás seguro?',
        text: 'Se va a confirmar la cita y se creara un ingreso!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si, confirmar cita!',
        cancelButtonText: 'Cancelar',
      });

      if (result.isConfirmed) {
        this.socket.emit('activeAppointment', {
          appointmentId: appointmen.appointmentId,
        });
        this.modalService.closeModal();
      }
    }
  }

  getRescheduleAppointment(appointment: any): RescheduleAppointment {
    let idUserGlobal = '';

    this.statesNgrxService.getDataGlobalUser().subscribe((resp: any) => {
      idUserGlobal = resp._id;
    });

    return {
      appointmentDate: appointment.appointmentDate.split('T')[0],
      appointmentId: appointment.appointmentId,
      branchId: appointment.branch.branchId,
      doctorId: appointment.doctor.doctorId,
      hour: appointment.hour,
      patient: appointment.patient.patientId,
      shiftId: appointment.shiftId,
      subServiceId: appointment.subservice.subServiceId,
      whoScheduled: idUserGlobal,
      appointmentReason: appointment.appointmentReason,
      office: appointment.office,
      receiptNumber: appointment.receiptNumber,
    };
  }

  rescheduleAppointmentActivate(appointment: any) {
    this.endPointCalendar = appointment.doctor.doctorId;

    this.rescheduling.emit(true);

    this.appointmentsService
      .getAppointmentsActiveSubservices(appointment.branch.branchId)
      .then((resp) => {
        const nData: AppointmentActiveSubservice[] = [...resp.body];
        if (this.inputSubServiceId) {
          this.activeSubService = nData.filter(
            (s) => s.subServiceId === this.inputSubServiceId
          );
        } else {
          this.activeSubService = nData;
        }
        this.iniciarUserForm(appointment);
      });

    this.inputData = {
      branchId: appointment.branch.branchId,
      subServiceId: appointment.subservice.subServiceId,
      doctorId: appointment.doctor.doctorId,
    };

    this.appointmentsSelected = this.getRescheduleAppointment(appointment);
    this.getInfoPatient(appointment);
    this.getInfoAppointment(appointment);
  }

  cancelAppointmentActivate() {
    this.rescheduling.emit(false);
    this.appointmentsSelected = undefined!;
    this.activeSubService = [];
    this.doctors = [];
  }

  async citasFormValid(): Promise<boolean> {
    if (this.citasForm.invalid) {
      this.citasForm?.markAllAsTouched();
      const requiredFields = ['servicio', 'medico', 'motivo'];
      const swalResult = await this.swalService.lauchSwalAsync(
        '',
        'Debes registrar los datos en rojo para continuar',
        'warning'
      );
      if (swalResult) {
        setTimeout(() => {
          const scrollTo = requiredFields.find(
            (field) => this.citasForm?.controls[field].invalid
          );
          if (scrollTo) {
            this.hacerScroll(
              `idInput${scrollTo.charAt(0).toUpperCase() + scrollTo.slice(1)}`
            );
          }
        }, 300);
      }
      return false;
    } else {
      return true;
    }
  }

  calendarEvent(cita: any) {
    this.citasFormValid().then((resp) => {
      if (resp) {
        this.hacerScroll('idReprogramarCita', 130);
        this.citaText = '';

        setTimeout(() => {
          this.citaText = this.fechaHoraString({
            appointmentDate: cita.date,
            hour: cita.hour,
          });
        }, 200);
        this.infoAppointment.fechaCita = cita.date;
        this.infoAppointment.hour = cita.hour;
        if (this.appointmentsSelected) {
          if (cita) {
            this.appointmentsSelected.appointmentDate =
              cita.date ?? this.appointmentsSelected.appointmentDate;

            this.appointmentsSelected.hour =
              cita.hour ?? this.appointmentsSelected.hour;
          }
        }
        setTimeout(() => {
          const checkboxElement = document.getElementById('basic_checkbox_orden') as HTMLInputElement;
          if (checkboxElement) {
            const event = new Event('change');
            checkboxElement.checked = true;
            this.onCheckboxChange({ target: checkboxElement });
          } else {
          }
        }, 0);
      }
    });
  }

  errorNoData() { }

  async appointmentUpdate() {

    if (this.citaText === this.citaTextTemp && !this.isAnotherDoctor) {
      this.swalService.lauchSwal(
        '',
        'No se han realizado cambios.',
        'info'
      );
    } else {
      let reciboElement = document.getElementById('reciboDescuento') as HTMLElement;

      this.citasFormValid().then(async (resp) => {
        if (resp) {
          const result = await Swal.fire({
            title: 'Estás seguro?',
            text: 'Se va a reprogramar la cita!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, reprogramar cita!',
            cancelButtonText: 'Cancelar',
          });

          if (result.isConfirmed === true && this.ordenDescuento && reciboElement) {
            this.captureTicket(reciboElement);
          }
          if (result.isConfirmed === true && this.appointmentsSelected) {
            this.appointmentsService
              .putAppointmentsReschedule(this.appointmentsSelected)
              .then((resp) => {
                if (resp.ok) {
                  this.cancelAppointmentActivate();
                  this.modalService.closeModal();
                }
              })
              .catch((err) => { });
          }
        }
      });
    }
  }

  async appointmentCancel(appointment: any) {
    const { value: reason } = await Swal.fire({
      title: 'Estás seguro?',
      text: 'Se va a cancelar la cita!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí, cancelar la cita!',
      cancelButtonText: 'No, cancelar la cita!',
      input: 'text',
      inputPlaceholder: 'Motivo de cancelación',
      inputValidator: (value) => {
        if (!value) {
          return 'Debes registrar un motivo de cancelación!';
        }
        return null;
      },
    });

    if (reason) {
      let idUserGlobal = '';
      this.statesNgrxService.getDataGlobalUser().subscribe((resp: any) => {
        idUserGlobal = resp._id;
      });

      const cancelData = {
        appointmentId: appointment.appointmentId,
        reason: reason,
        whoCancelled: idUserGlobal,
      };

      this.appointmentsService
        .putAppointmentsCancel(cancelData)
        .then((resp) => {
          if (resp.ok) {
            this.modalService.closeModal();
          }
        });
    }
  }

  onCheckboxChange(event: any) {
    this.ordenDescuento = event.target.checked;
  }

  getInfoPatient(appointment: any) {
    this.infoPatient.nombres = this.user?.names
      ? this.user?.names
      : this.userFromAdvisor?.user?.dataSocialUser?.nombres;
    this.infoPatient.apellidos = this.user?.lastNames
      ? this.user?.lastNames
      : this.userFromAdvisor?.user?.dataSocialUser?.apellidos;
    this.infoPatient.numeroDocumento = this.documentNumber
      ? this.documentNumber
      : this.userFromAdvisor?.user?.numeroDocumento;

    if (appointment?.patient && appointment?.patient?.owner?.cellPhones) {
      this.infoPatient.phone = appointment.patient.owner.cellPhones[0].phone;
    } else if (appointment?.patient && appointment?.patient?.cellPhones) {
      this.infoPatient.phone = appointment.patient.cellPhones[0].phone;
    } else {
      this.infoPatient.phone = this.userFromAdvisor?.user?.cellPhones[0].phone
        ? this.userFromAdvisor?.user?.cellPhones[0].phone
        : null;
    }
  }

  getInfoAppointment(appointment: any) {

    this.infoAppointment.neighbor =
      appointment?.branch?.addressSettings?.neighbor;
    this.infoAppointment.address =
      appointment?.branch?.addressSettings?.address;
    this.infoAppointment.entity = appointment?.branch?.name;

    if (appointment?.branch?.cellPhones) {
      this.infoAppointment.cellphone = appointment?.branch?.cellPhones[0].phone;
    }

    if (
      this.appointmentType == 'Veterinary' ||
      this.switchPerfil === 'citasActivasMascotas'
    ) {
      this.infoPet = appointment.patient;
    }

    this.infoAppointment.fechaCita = this.appointmentsSelected?.appointmentDate;
    if (this.appointmentsSelected) {
      this.infoAppointment.hour = this.appointmentsSelected?.hour;
      this.infoAppointment.idCita = this.appointmentsSelected?.receiptNumber;
    }
  }

  generateImage(htmlNode: HTMLElement): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      try {
        html2canvas(htmlNode, {
          scale: 2, // Incrementa la escala para mejorar la calidad
          // useCORS: true, // Permite cargar imágenes de recursos remotos con CORS
          // logging: true,
          allowTaint: false,
        })
          .then((canvas) => {
            const dataUrl = canvas.toDataURL('image/png', 1.0);
            resolve(dataUrl);
          })
          .catch((error) => {
            reject(error);
          });
      } catch (error) {
        reject(error);
      }
    });
  }

  captureTicket(reciboElement: HTMLElement): void {
    try {
      let elem = document.querySelector('.ticket-container') as HTMLElement;

      if (elem) {
        this.generateImage(elem,)
          .then((dataUrl) => this.drawImageOnCanvasUser(dataUrl, reciboElement))
          .catch((error) => {
          });
      } else {
      }
    } catch (error) {
    }
  }

  drawImageOnCanvasUser(img64: string, reciboElement: HTMLElement): void {
    let img = new Image();
    img.src = img64;

    if (reciboElement) {
      img.addEventListener('load', () => {
        let canvas = document.createElement('canvas');
        reciboElement.appendChild(canvas);

        const context = canvas.getContext('2d');

        if (context) {
          // Ajustar el tamaño del canvas al tamaño de la imagen
          canvas.width = img.width;
          canvas.height = img.height;

          // Dibujar la imagen en el canvas
          context.drawImage(img, 0, 0);

          // Obtener la imagen del canvas con alta calidad
          let img64Canvas = canvas.toDataURL('image/jpeg', 1.0); // Calidad máxima

          if (this.infoPatient && this.infoPatient.phone) {
            const formattedDate = this.formatDate(
              this.infoAppointment?.fechaCita
            );
            const data: SendTicketAppointment = {
              caption: `Se ha reprogramado una cita para el servicio de ${this.infoAppointment?.subservice
                } en ${this.infoAppointment?.entity} con direccion ${this.infoAppointment?.address
                }. ${formattedDate
                  ? `Para el dia ${formattedDate} a las ${this.formatTime(
                    this.infoAppointment?.hour
                  )}`
                  : ''
                }. Recuerda cumplir con las normas de bioseguridad y llegar 15 minutos antes de la cita.`,
              number: this.infoPatient?.phone,
              mediab64: img64Canvas,
            };

            this.userService.sendTicketAppointment(data);
          }
        }
      });
    }

  }

  formatDate(dateString: string): string {
    const [year, month, day] = dateString.split('-');

    const months = [
      'enero',
      'febrero',
      'marzo',
      'abril',
      'mayo',
      'junio',
      'julio',
      'agosto',
      'septiembre',
      'octubre',
      'noviembre',
      'diciembre',
    ];

    const formattedDate = `${parseInt(day)} de ${months[parseInt(month) - 1]
      } del ${year}`;
    return formattedDate;
  }

  formatTime(time: string): string {
    if (!time) {
      return '';
    }
    const [hours, minutes] = time.split(':').map(Number);
    const period = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = hours % 12 || 12; // Convert 0 to 12 for 12 AM
    return `${this.padZero(formattedHours)}:${this.padZero(minutes)} ${period}`;
  }

  private padZero(num: number): string {
    return num.toString().padStart(2, '0');
  }
}
