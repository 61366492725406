import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { MenuWeb, Role } from 'src/app/interfaces/auth.interface';
import { Store } from '@ngrx/store';
import { getMenu, getRoles, getRolSelect, rolSession } from 'src/app/pages/session/state/session.selectors';
import { StatesNgrxService } from 'src/app/services/states-ngrx.service';
import { SweetAlertService } from 'src/app/services/sweet-alert.service';
import { InformationService } from '@services';
import { Subject, switchMap, takeUntil } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  @Input() rolSelect: Role = {
    rol: '',
    name: '',
    _idData: '',
    avatar: '',
  };

  isSidebarOpen: boolean = false;
  menuItems: MenuWeb[] | any = [];
  roles: Role[] = [];
  opcion = '';
  expandedOption: string | null = null;
  isUserProfileExpanded = false;
  private destroy$ = new Subject<void>();
  selectedSubMenu: string | null = null; 

  constructor(
    private store: Store,
    private statesNgrxService: StatesNgrxService,
    private swalService: SweetAlertService,
    private infoService: InformationService,
    // private router: Router

  ) {}

  ngOnInit(): void {
    this.dispatchSelectors();
  }


  dispatchSelectors() {
    this.store.select(rolSession)
      .pipe(
        takeUntil(this.destroy$),  
        switchMap((rol) => {
          // console.log('rolSession', rol);
        
          switch (rol) {
            case 'user':
              // console.log('rol user');
              return this.infoService.getMenusRoles('menuWebUser');
            case 'doctor':
              // console.log('rol doctor');
              return this.infoService.getMenusRoles('menuWebDoctor');
            case 'provider':
              // console.log('rol provider');
              return this.infoService.getMenusRoles('menuWebAdmin');
            default:
              // console.log('rol asesor');
              return this.infoService.getMenusRoles('menuWebAdvisor');
          }
        })
      )
      .subscribe((resp: any) => {
        // console.log('menus', resp.body);
        this.menuItems = resp.body;
      });


    this.store.select(getRoles)
      .pipe(takeUntil(this.destroy$))
      .subscribe((roles) => {
        this.roles = roles;
        // console.log('this.roles', this.roles);
      });
  }

  selectSubMenu(subMenuName: string) {
    this.selectedSubMenu = subMenuName;  // Guarda el submenú seleccionado para mas despues tenerlo como referencia al momento de seleccionar las opciones
  }

  toggleSubMenu(option: string) {
    // Alterna el submenú al hacer click
    this.expandedOption = this.expandedOption === option ? null : option;
  }
  
  toggleSidebar() {
    this.isSidebarOpen = !this.isSidebarOpen;
  }
  toggleUserProfile() {
    // Alterna la sección del perfil de usuario
    this.isUserProfileExpanded = !this.isUserProfileExpanded;
  }

  changeRol(role: Role) {
    this.statesNgrxService.changeRol(role);
    this.swalService.showInfo('', 'Se cambio de perfil a: ' + role.name );
  }

  get getProfileRoleUrl(): string {
    return `/${this.rolSelect.rol}/profile`;
  }

  getProfileRoleName(rol: string): string {
    switch (rol) {
      case 'user':
        return 'Usuario';
      case 'provider':
        return 'Empresa';
      case 'doctor':
        return 'Profesional de salud';
      default:
        return 'Perfil';
    }
  }

  ngOnDestroy() {
    // Emitir la destrucción para desuscribirnos de todos los observables
    this.destroy$.next();
    this.destroy$.complete();
  }
}
